import { useQuery, useMutation } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';

import { useConfirmDialog } from 'components/contexts/ConfirmContext';

import ErrorAlert from 'components/ErrorAlert';

import Form from 'components/form/Form';
import TextField from 'components/form/TextField';
import CheckboxField from 'components/form/CheckboxField';
import SubmitButton from 'components/form/SubmitButton';

import DesktopLayout, { NarrowContent } from 'web/components/Layout';

import { QUERY_DOCUMENT_NODES } from 'api/queries/documentQueries';
import { RENAME_ONE_DOCUMENT_NODE } from 'api/mutations/documentMutations';

export default function FileEdit() {
	const { companyId, documentNodeId } = useParams();
	const { verify } = useConfirmDialog();
	const navigate = useNavigate();

	const {
		data: { documentNode } = { documentNode: {} },
		loading: documentNodeLoading,
		error: documentNodeError,
	} = useQuery(QUERY_DOCUMENT_NODES, {
		variables: {
			documentId: documentNodeId,
			tenantId: companyId,
		},
	});

	const [
		renameDocumentNode,
		{ loading: renameDocumentNodeLoading, error: renameDocumentNodeError },
	] = useMutation(RENAME_ONE_DOCUMENT_NODE, {
		refetchQueries: ['paginateDocumentNodes'],
	});

	return (
		<DesktopLayout
			breadcrumbs={[
				{
					label: 'Rediger dokument',
				},
			]}
		>
			<NarrowContent>
				{!documentNodeLoading && documentNode && (
					<Form
						isLoading={renameDocumentNodeLoading}
						isDisabled={renameDocumentNodeLoading}
						values={{
							name: documentNode.name,
							manuallySigned: documentNode.manuallySigned,
						}}
						onSubmit={async ({ name, manuallySigned }) => {
							try {
								if (
									!documentNode.manuallySigned &&
									manuallySigned
								) {
									if (
										!(await verify({
											title: 'Bekreft signering',
											text: "Er du sikker på at du ønsker å endre dokumentets status til 'Manuelt signert'? Eventuelle aktive signaturforespørsler vil bli kansellert.",
										}))
									) {
										return;
									}
								}

								const { data } = await renameDocumentNode({
									variables: {
										_id: documentNodeId,
										name,
										manuallySigned,
									},
								});

								if (data?.file?._id) {
									navigate('../');
								}
							} catch (error) {
								console.error(error);
							}
						}}
					>
						<ErrorAlert error={documentNodeError} />
						<ErrorAlert error={renameDocumentNodeError} />

						<TextField required name="name" label="Navngi filen" />

						<CheckboxField
							name="manuallySigned"
							label="Er dette dokumentet allerede signert?"
						/>

						<SubmitButton
							variant="contained"
							size="large"
							icon={<SaveIcon />}
						>
							Oppdater
						</SubmitButton>
					</Form>
				)}
			</NarrowContent>
		</DesktopLayout>
	);
}

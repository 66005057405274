import { createContext, useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export const ConfirmContext = createContext({ confirm: async () => {} });

let onConfirm = () => {};

export function useConfirmDialog() {
	const context = useContext(ConfirmContext);

	if (context === undefined) {
		throw new Error(
			'useConfirmDialog must be used within a ConfirmProvider'
		);
	}

	return context;
}

export default function ConfirmProvider({ children }) {
	const [isOpen, setIsOpen] = useState(false);
	const [message, setMessage] = useState({});

	return (
		<ConfirmContext.Provider
			value={{
				verify: message => {
					setMessage(message);
					setIsOpen(true);

					return new Promise(resolve => {
						onConfirm = value => {
							setIsOpen(false);
							resolve(value);
						};
					});
				},
			}}
		>
			{children}

			<Dialog
				title=""
				open={isOpen}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle id="alert-dialog-slide-title">
					{message.title}
				</DialogTitle>

				{message.text && (
					<DialogContent id="alert-dialog-slide-description">
						{message.text}
					</DialogContent>
				)}

				<DialogActions>
					<Button onClick={() => onConfirm(false)}>Nei</Button>

					<Button onClick={() => onConfirm(true)}>Ja</Button>
				</DialogActions>
			</Dialog>
		</ConfirmContext.Provider>
	);
}

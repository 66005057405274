import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Login from 'web/screens/Login';
import Invite from 'web/screens/Invite';
import AdminLogin from 'web/screens/AdminLogin';
import ForgotPassword from 'web/screens/ForgotPassword';
import SetNewPassword from 'web/screens/SetNewPassword';
import UnauthorizedNotFound from 'web/screens/UnauthorizedNotFound';

import RouteRedirect from 'web/RouteRedirect';

export default function UnauthorizedApp() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Login />} />

				<Route path="/invitasjon/" element={<Invite />} />

				<Route path="/admin-innlogging/" element={<AdminLogin />} />

				<Route path="/glemt-passord/" element={<ForgotPassword />} />

				<Route path="/nytt-passord/" element={<SetNewPassword />} />

				<Route path="/404/" element={<UnauthorizedNotFound />} />

				<Route path="*" element={<RouteRedirect redirect="/" />} />
			</Routes>
		</BrowserRouter>
	);
}

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from 'web/screens/Home';

import AuditorCompaniesArchive from 'web/screens/AuditorCompaniesArchive';
import CompaniesSingle from 'web/screens/CompaniesSingle';

import CompaniesDocumentsSingle from 'web/screens/CompaniesDocumentsSingle';
import ShareDocumentNode from 'web/screens/ShareDocumentNode';
import SendSigningRequest from 'web/screens/SendSigningRequest';

import UserProfile from 'web/screens/UserProfile';

import NotFound from 'web/screens/NotFound';

import RouteRedirect from 'web/RouteRedirect';

export default function AuditorRoutes() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Home />} />

				<Route path="profil" element={<UserProfile />} />

				<Route path="bedrifter">
					<Route index element={<AuditorCompaniesArchive />} />

					<Route path=":companyId">
						<Route index element={<CompaniesSingle />} />

						<Route path=":documentNodeId">
							<Route
								index
								element={<CompaniesDocumentsSingle />}
							/>
							<Route
								path="signer"
								element={<SendSigningRequest />}
							/>
							<Route path="del" element={<ShareDocumentNode />} />
						</Route>
					</Route>
				</Route>

				<Route path="404" element={<NotFound />} />

				<Route path="*" element={<RouteRedirect redirect="/404" />} />
			</Routes>
		</BrowserRouter>
	);
}

import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import ModalLayout from 'components/modals/ModalLayout';
import Form from 'components/form/Form';
import AutocompleteField from 'components/form/AutocompleteField';
import TextField from 'components/form/TextField';

import ErrorMessage from 'components/ErrorMessage';

import { PAGINATE_USERS } from 'api/queries/userQueries';
import { ADD_OWNER_TO_COMPANY } from 'api/mutations/tenantMutations';

const FormActions = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 15px;

	> div {
		width: auto;
	}
`;

export default function AddOwnerModal({ companyId, onClose }) {
	const { notify } = useSnackbar();

	const {
		data: { users } = { users: { count: 0, items: [] } },
		loading: userLoading,
		error: userError,
	} = useQuery(PAGINATE_USERS, {
		variables: { orderBy: 'name', order: 1 },
	});

	const [addOwner, { loading: addOwnerLoading, error: addOwnerError }] =
		useMutation(ADD_OWNER_TO_COMPANY, {
			refetchQueries: ['findOneTenant'],
		});

	return (
		<ModalLayout
			title="Legg til reell rettighetshaver"
			onClose={onClose}
			maxWidth="800px"
		>
			<ErrorMessage errors={[userError, addOwnerError]} />

			<Form
				isLoading={userLoading || addOwnerLoading}
				isDisabled={userLoading || addOwnerLoading}
				onSubmit={async ({ userId, note }) => {
					try {
						if (!userId) {
							return;
						}

						const { data } = await addOwner({
							variables: {
								_id: companyId,
								userId,
								note,
							},
						});

						if (data && data.added) {
							notify('Rettighetshaver ble lagt til');

							onClose();
						}
					} catch (err) {
						console.error(err);
					}
				}}
			>
				<AutocompleteField
					name="userId"
					label="Reell rettighetshavere"
					options={users.items.map(user => ({
						value: user._id,
						label: user.name,
						subdued:
							user.role === undefined ||
							user.role === null ||
							user.role == 'silent',
						tooltip: [
							<Stack
								key="1"
								direction="row"
								alignItems="center"
								gap={1}
							>
								<MailIcon />
								{user.email || 'Mangler e-post'}
							</Stack>,
							<Stack
								key="2"
								direction="row"
								alignItems="center"
								gap={1}
							>
								<PhoneIcon />
								{user.phone || 'Mangler telefon'}
							</Stack>,
						],
					}))}
				/>

				<TextField
					name="note"
					label="Notat"
					maxRows="5"
					multiline={true}
				/>

				<FormActions>
					<Button onClick={() => onClose()}>Avbryt</Button>

					<Button
						size="medium"
						variant="contained"
						color="primary"
						type="submit"
						disabled={userLoading || addOwnerLoading}
					>
						Legg til
					</Button>
				</FormActions>
			</Form>
		</ModalLayout>
	);
}

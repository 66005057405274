import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import useSortOrder from 'hooks/useSortOrder';
import useLocalStorage from 'hooks/useLocalStorage';

import { useUser } from 'web/contexts/UserContext';

import UsersCreateDialog from 'web/screens/dialogs/users/UsersCreateDialog';

import ErrorMessage from 'components/ErrorMessage';
import { InlineLoading } from 'components/Loading';

import DesktopLayout, {
	ArchiveHeader,
	ArchiveHeaderRow,
	Content,
} from 'web/components/Layout';
import SearchBar from 'web/components/SearchBar';
import AccountantRoleFilterLegacy from 'components/filters/AccountantRoleFilterLegacy';

import Table, { TableRow, TableCell } from 'web/components/Table';
import Link from 'web/components/Link';
import SortIcon from 'web/components/SortIcon';
import CreateFabButton from 'web/components/CreateFabButton';

import { PAGINATE_ACCOUNTANTS } from 'api/queries/userQueries';

export default function AccountantsArchive() {
	const navigate = useNavigate();
	const { userHasMinRole } = useUser();
	const [{ orderBy, order }, setNewOrder] = useSortOrder('name');
	const [accountantsList, setAccountantsList] = useState([]);
	const [accountantsFilter, setAccountantsFilter] = useLocalStorage(
		'falstad-web-accountants-filter'
	);

	const [search, setSearch] = useState('');
	const [showUserCreateDialog, setShowUserCreateDialog] = useState(false);

	const {
		data: { accountants = { count: 0, items: [] } } = {
			accountants: { count: 0, items: [] },
		},
		loading,
		error,
	} = useQuery(PAGINATE_ACCOUNTANTS, {
		variables: {
			order,
			orderBy,
			search,
		},
		fetchPolicy: 'cache-and-network',
		errorPolicy: 'all',
		notifyOnNetworkStatusChange: true,
	});

	useEffect(() => {
		if (error || loading) return;

		if (accountantsFilter && accountantsFilter.value) {
			setAccountantsList(
				accountants.items.filter(accountant => {
					switch (accountantsFilter.value) {
						case 'accountant':
							return (
								accountant.accountantRoles?.accountantTenants
									?.length > 0
							);

						case 'accountmanager':
							return (
								accountant.accountantRoles
									?.accountManagerTenants?.length > 0
							);

						case 'controller':
							return (
								accountant.accountantRoles?.controllerTenants
									?.length > 0
							);

						default:
							return true;
					}
				})
			);
		} else {
			setAccountantsList(accountants.items);
		}
	}, [accountants.items, accountantsFilter, error, loading]);

	return (
		<DesktopLayout
			breadcrumbs={[
				{
					label: 'Regnskapsførere',
				},
			]}
			title="Regnskapsførere"
		>
			<Content>
				<ErrorMessage errors={error} />

				{!error && (
					<>
						<ArchiveHeader>
							<ArchiveHeaderRow>
								<SearchBar
									label={'Søk etter regnskapsfører'}
									updateSearch={setSearch}
								/>

								<AccountantRoleFilterLegacy
									accountantsFilter={accountantsFilter}
									setAccountantsFilter={setAccountantsFilter}
								/>
							</ArchiveHeaderRow>
						</ArchiveHeader>

						<Table columns="7">
							<TableRow header>
								<TableCell
									start="1"
									end="3"
									onClick={() => setNewOrder('name')}
								>
									<span>Navn</span>

									<SortIcon
										field="name"
										orderBy={orderBy}
										order={order}
									/>
								</TableCell>

								<TableCell>Regnskapsfører</TableCell>

								<TableCell>Kundeansvarlig</TableCell>

								<TableCell>Oppdragskontrollør</TableCell>

								<TableCell>Stilling</TableCell>

								<TableCell onClick={() => setNewOrder('role')}>
									<span>Rolle</span>

									<SortIcon
										field="role"
										orderBy={orderBy}
										order={order}
									/>
								</TableCell>
							</TableRow>

							{!loading && accountantsList?.length === 0 && (
								<TableRow transparent>
									<TableCell start="1" end="-1" center>
										Ingen regnskapsførere ble funnet
									</TableCell>
								</TableRow>
							)}

							{accountantsList.map(accountant => (
								<Link
									to={`/brukere/${accountant._id}`}
									key={accountant._id}
								>
									<TableRow hover>
										<TableCell start="1" end="3">
											{accountant.name}
										</TableCell>

										<TableCell>
											{accountant?.accountantRoles
												?.accountantTenants?.length ??
												0}
										</TableCell>

										<TableCell>
											{accountant?.accountantRoles
												?.accountManagerTenants
												?.length ?? 0}
										</TableCell>

										<TableCell>
											{accountant?.accountantRoles
												?.controllerTenants?.length ??
												0}
										</TableCell>

										<TableCell>
											{accountant?.position}
										</TableCell>

										<TableCell>
											{accountant.roleName}
										</TableCell>
									</TableRow>
								</Link>
							))}

							{loading && (
								<TableRow transparent>
									<TableCell start="1" end="-1">
										<InlineLoading />
									</TableCell>
								</TableRow>
							)}

							{!loading && accountantsList?.length !== 0 && (
								<TableRow footer>
									<TableCell start="1" end="-1" right>
										Viser {accountantsList.length} av{' '}
										{accountants.count} regnskapsførere
									</TableCell>
								</TableRow>
							)}
						</Table>
					</>
				)}
			</Content>

			{userHasMinRole('accountant') && (
				<CreateFabButton
					onClick={() => setShowUserCreateDialog(true)}
				/>
			)}

			<UsersCreateDialog
				title="Opprett regnskapsfører"
				defaultRole="accountant"
				open={showUserCreateDialog}
				onClose={() => setShowUserCreateDialog(false)}
				onSubmitSuccess={user => {
					setShowUserCreateDialog(false);

					navigate(`/brukere/${user._id}/`);
				}}
			/>
		</DesktopLayout>
	);
}

import { roles } from 'server/context/roles';

import { useAppFilters } from 'web/contexts/AppFiltersContext';

import Filter from 'components/filters/Filter';

const allOption = { value: '', label: 'Alle brukere' };

export default function UserRolesFilter() {
	const { userRole, setUserRole } = useAppFilters();
	return (
		<Filter
			name="userroles-filter"
			label="Vis brukere"
			value={userRole || allOption}
			options={[
				allOption,
				...roles.map(({ _id, name }) => ({
					value: _id,
					label: name,
				})),
			]}
			onChange={setUserRole}
		/>
	);
}

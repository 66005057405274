import { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import Button from '@mui/material/Button';

import { formatDate } from 'utils/dates';

import useSortOrder from 'hooks/useSortOrder';

import ErrorMessage from 'components/ErrorMessage';
import { InlineLoading } from 'components/Loading';

import DesktopLayout, {
	ArchiveHeader,
	ArchiveHeaderRow,
	Content,
} from 'web/components/Layout';
import SearchBar from 'web/components/SearchBar';
import SortIcon from 'web/components/SortIcon';
import Table, { TableRow, TableCell } from 'web/components/Table';
import Link from 'web/components/Link';

import { PAGINATE_COMPANY_APPLICATIONS } from 'api/queries/companyApplicationQueries';

const ActiveStatusButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 40px;
`;

export default function CompanyApplicationsArchive() {
	const [{ orderBy, order }, setNewOrder] = useSortOrder('createdAt', -1);
	const [search, setSearch] = useState('');
	const [isArchived, setIsArchived] = useState(false);

	const {
		data: { companyApplications } = { companyApplications: {} },
		loading,
		error,
	} = useQuery(PAGINATE_COMPANY_APPLICATIONS, {
		variables: {
			order,
			orderBy,
			search,
			isArchived,
		},
		fetchPolicy: 'cache-and-network',
		errorPolicy: 'all',
		notifyOnNetworkStatusChange: true,
	});

	return (
		<DesktopLayout breadcrumbs={[{ label: 'Søknader' }]}>
			<Content>
				<ErrorMessage errors={error} />

				{!error && (
					<>
						<ArchiveHeader>
							<ArchiveHeaderRow>
								<SearchBar
									label={'Søk etter søknader'}
									updateSearch={setSearch}
								/>
							</ArchiveHeaderRow>
						</ArchiveHeader>

						<Table columns="8">
							<TableRow header>
								<TableCell
									start="1"
									end="8"
									onClick={() => setNewOrder('companyName')}
								>
									<span>Bedriftsnavn</span>

									<SortIcon
										field="companyName"
										orderBy={orderBy}
										order={order}
									/>
								</TableCell>

								<TableCell
									onClick={() => setNewOrder('createdAt')}
								>
									<span>Dato</span>

									<SortIcon
										field="createdAt"
										orderBy={orderBy}
										order={order}
									/>
								</TableCell>
							</TableRow>

							{!loading &&
								companyApplications?.items?.length === 0 && (
									<TableRow transparent>
										<TableCell start="1" end="-1" center>
											Ingen søknader ble funnet
										</TableCell>
									</TableRow>
								)}

							{companyApplications?.items?.map(application => (
								<Link
									key={application._id}
									to={`/soknader/${application._id}/`}
								>
									<TableRow hover>
										<TableCell start="1" end="8">
											{application.companyName}
										</TableCell>
										<TableCell>
											{formatDate(
												application.createdAt,
												'DD.MM.YYYY'
											)}
										</TableCell>
									</TableRow>
								</Link>
							))}

							{loading && (
								<TableRow transparent>
									<TableCell start="1" end="-1">
										<InlineLoading />
									</TableCell>
								</TableRow>
							)}

							{!loading &&
								companyApplications?.items?.length !== 0 && (
									<TableRow footer>
										<TableCell start="1" end="-1" right>
											Viser{' '}
											{companyApplications.items.length}{' '}
											av {companyApplications.count}{' '}
											{isArchived
												? 'arkiverte søknader'
												: 'søknader'}
										</TableCell>
									</TableRow>
								)}
						</Table>

						{!loading && (
							<ActiveStatusButtonWrapper>
								<Button
									variant="contained"
									color="primary"
									onClick={() => setIsArchived(prev => !prev)}
								>
									{isArchived
										? 'Skjul arkiverte'
										: 'Vis arkiverte'}
								</Button>
							</ActiveStatusButtonWrapper>
						)}
					</>
				)}
			</Content>
		</DesktopLayout>
	);
}

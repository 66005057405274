import { useState } from 'react';
import styled from 'styled-components';
import { useQuery, useMutation } from '@apollo/client';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { formatDate } from 'utils/dates';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import StatusIcon from 'components/StatusIcon';
import { AnnualSettlementControlStatusPill } from 'components/Pill';

import { TableCell } from 'web/components/Table';

import { GET_COMPANY_CONTROL_FOR_COMPANY } from 'api/queries/companyControlQueries';
import { ADD_ANNUAL_FINANCIAL_STATUS } from 'api/mutations/tenantMutations';

const Status = styled.div`
	background: ${p => p.theme.palette.background.gray};
	border-radius: 100px;
	font-size: 12px;
	display: inline-block;
	padding: 4px 20px;
`;

const TransparentButton = styled.button`
	background: transparent;
	border: none;
	cursor: pointer;
	height: 100%;
	width: 100%;
`;

const thisYear = new Date().getFullYear();
const previousYear = thisYear - 1;

export default function AnnualSettlementControlStatus({
	companyId,
	selfRevisionRequired = false,
	reviewedAnnualFinancialStatus = null,
}) {
	const [isOpen, setIsOpen] = useState(false);

	const { data: { control } = { control: null }, loading } = useQuery(
		GET_COMPANY_CONTROL_FOR_COMPANY,
		{
			variables: {
				companyId,
				controlTypeValue: 'arsregnskap-skattemelding',
				year: previousYear,
			},
		}
	);

	if (loading) {
		return <div />;
	}

	if (!control) {
		if (reviewedAnnualFinancialStatus?.readyForReview) {
			return (
				<TableCell to={`/bedrifter/${companyId}/rediger/?tab=4`} center>
					<AnnualSettlementControlStatusPill
						$statusCode="READY"
						title={`Årsregnskap ${thisYear} satt klart ${formatDate(
							reviewedAnnualFinancialStatus.reviewedAt,
							'DD.MM.YY [kl] HH:mm'
						)}`}
					>
						Klar til kontroll
					</AnnualSettlementControlStatusPill>
				</TableCell>
			);
		}

		if (reviewedAnnualFinancialStatus?.notRelevant) {
			return (
				<TableCell to={`/bedrifter/${companyId}/rediger/?tab=4`} center>
					<Status>Ikke relevant</Status>
				</TableCell>
			);
		}

		if (selfRevisionRequired) {
			return (
				<div>
					<TransparentButton onClick={() => setIsOpen(true)}>
						<StatusIcon
							icon="xmark"
							errorTitle={`Kontrollskjema for ${previousYear} mangler!`}
							error={true}
						/>
					</TransparentButton>

					<ConfirmDialog
						companyId={companyId}
						isOpen={isOpen}
						setIsOpen={setIsOpen}
					/>
				</div>
			);
		}

		return (
			<div>
				<TransparentButton onClick={() => setIsOpen(true)} />

				<ConfirmDialog
					companyId={companyId}
					isOpen={isOpen}
					setIsOpen={setIsOpen}
				/>
			</div>
		);
	}

	return (
		<TableCell
			to={`/bedrifter/${companyId}/kontroller/${control._id}/rediger/`}
			center
		>
			<AnnualSettlementControlStatusPill
				$statusCode={control.status.code}
				title={`Oppdatert ${formatDate(
					control.status.updatedAt,
					'DD.MM.YY [kl] HH:mm'
				)}`}
			>
				{control.status.label}
			</AnnualSettlementControlStatusPill>
		</TableCell>
	);
}

function ConfirmDialog({ companyId, isOpen, setIsOpen }) {
	const { notify } = useSnackbar();

	const [addAnnualFinancialSatus] = useMutation(ADD_ANNUAL_FINANCIAL_STATUS, {
		refetchQueries: ['paginateTenants'],
	});

	function handleClose() {
		setIsOpen(false);
	}

	async function handleSetReadyForControl() {
		try {
			const { data } = await addAnnualFinancialSatus({
				variables: {
					_id: companyId,
					year: previousYear,
				},
			});

			if (data?.added) {
				notify(`Regnskap ${previousYear} er nå klart for gjennomgang`);
				setIsOpen(false);
				return;
			}

			throw new Error('Kunne ikke sette regnskap klart for gjennomgang');
		} catch (err) {
			console.error(err);
			notify(
				`Noe gikk galt. Kunne ikke sette regnskap ${previousYear} klart for gjennomgang`,
				'error'
			);
			setIsOpen(false);
		}
	}

	async function handleSetNotRelevant() {
		try {
			const { data } = await addAnnualFinancialSatus({
				variables: {
					_id: companyId,
					year: previousYear,
					type: 'not-relevant',
				},
			});

			if (data?.added) {
				notify(`Regnskap ${previousYear} er nå satt som ikke aktuelt`);
				setIsOpen(false);
				return;
			}

			throw new Error('Kunne ikke sette regnskap som ikke aktuelt');
		} catch (err) {
			console.error(err);
			notify(
				`Noe gikk galt. Kunne ikke sette regnskap ${previousYear} som ikke aktuelt`,
				'error'
			);
			setIsOpen(false);
		}
	}

	return (
		<Dialog
			open={isOpen}
			onClose={handleClose}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
			fullWidth={true}
			maxWidth="sm"
		>
			<DialogTitle id="alert-dialog-slide-title">
				Årsregnskap {previousYear}
			</DialogTitle>

			<DialogContent id="alert-dialog-slide-description">
				Ønsker du å sette årsregnskapet for {previousYear} klar til
				kontroll?
			</DialogContent>

			<DialogActions>
				<Button onClick={() => setIsOpen(false)}>Avbryt</Button>

				<Button onClick={handleSetNotRelevant} variant="outlined">
					Merk som ikke relevant
				</Button>

				<Button
					onClick={handleSetReadyForControl}
					variant="contained"
					autoFocus
				>
					Sett klar til kontroll
				</Button>
			</DialogActions>
		</Dialog>
	);
}

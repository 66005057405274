import { formatDate } from 'utils/dates';

export default function UpdatedInfo({ updatedAt, updatedBy }) {
	if (!updatedAt && !updatedBy) {
		return null;
	}

	return (
		<div>
			<strong>Sist oppdatert</strong>{' '}
			{updatedAt && formatDate(updatedAt, 'DD.MM.YY [kl] HH:mm')}
			{updatedBy && ` av ${updatedBy}`}
		</div>
	);
}

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { useQuery } from '@apollo/client';

import useSortOrder from 'hooks/useSortOrder';

import { useUser } from 'web/contexts/UserContext';

import HelpPageCreateModal from 'web/screens/modals/HelpPageCreateModal';

import ErrorMessage from 'components/ErrorMessage';
import { InlineLoading } from 'components/Loading';

import DesktopLayout, {
	ArchiveHeader,
	ArchiveHeaderRow,
	Content,
} from 'web/components/Layout';
import SearchBar from 'web/components/SearchBar';
import Table, { TableRow, TableCell } from 'web/components/Table';
import Link from 'web/components/Link';
import SortIcon from 'web/components/SortIcon';
import CreateFabButton from 'web/components/CreateFabButton';

import { PAGINATE_HELP_PAGES } from 'api/queries/helpPageQueries';

export default function HelpPagesArchive() {
	const { user: currentUser } = useUser();
	const navigate = useNavigate();

	const [{ orderBy, order }, setNewOrder] = useSortOrder('name');
	const [search, setSearch] = useState('');
	const [createHelpPageModal, setCreateHelpPageModal] = useState(null);

	const {
		data: { helpPages = { count: 0, items: [] } } = {
			helpPages: { count: 0, items: [] },
		},
		loading,
		error,
	} = useQuery(PAGINATE_HELP_PAGES, {
		variables: {
			order,
			orderBy,
			search,
		},
	});

	return (
		<DesktopLayout
			breadcrumbs={[
				{
					label: 'Hjelpesider',
				},
			]}
		>
			<Content>
				<ErrorMessage errors={error} />

				{!error && (
					<>
						<ArchiveHeader>
							<ArchiveHeaderRow>
								<SearchBar
									label={'Søk etter hjelpeside'}
									updateSearch={setSearch}
								/>
							</ArchiveHeaderRow>
						</ArchiveHeader>

						<Table columns="6">
							<TableRow header>
								<TableCell
									start="1"
									end="5"
									onClick={() => setNewOrder('name')}
								>
									<span>Navn</span>

									<SortIcon
										field="name"
										orderBy={orderBy}
										order={order}
									/>
								</TableCell>
							</TableRow>

							{!loading && helpPages?.items?.length === 0 && (
								<TableRow transparent>
									<TableCell start="1" end="-1" center>
										Ingen hjelpesider ble funnet
									</TableCell>
								</TableRow>
							)}

							{helpPages?.items.map(helpPage => (
								<Link
									to={`/hjelpesider/${helpPage._id}/rediger/`}
									key={helpPage._id}
								>
									<TableRow hover>
										<TableCell start="1" end="5">
											{helpPage.name}
										</TableCell>
									</TableRow>
								</Link>
							))}

							{loading && (
								<TableRow transparent>
									<TableCell start="1" end="-1">
										<InlineLoading />
									</TableCell>
								</TableRow>
							)}

							{!loading && helpPages?.items?.length !== 0 && (
								<TableRow footer>
									<TableCell start="1" end="-1" right>
										Viser {helpPages.items.length} av{' '}
										{helpPages.count} hjelpesider
									</TableCell>
								</TableRow>
							)}
						</Table>
					</>
				)}
			</Content>

			{currentUser?.role === 'admin' && (
				<CreateFabButton onClick={() => setCreateHelpPageModal(true)} />
			)}

			{createHelpPageModal &&
				createPortal(
					<HelpPageCreateModal
						onClose={async newHelpPage => {
							setCreateHelpPageModal(false);

							if (newHelpPage) {
								navigate(
									`/hjelpesider/${newHelpPage._id}/rediger/`
								);
							}
						}}
					/>,
					document.getElementById('modal-root')
				)}
		</DesktopLayout>
	);
}

import { useMutation } from '@apollo/client';

import { useSnackbar } from 'web/contexts/SnackbarContext';
import { dropdownYears } from 'utils/dates';

import Form from 'components/form/Form';
import SubmitButton from 'components/form/SubmitButton';
import ModalLayout from 'components/modals/ModalLayout';
import AutocompleteField from 'components/form/AutocompleteField';
import ErrorMessage from 'components/ErrorMessage';

import { ADD_ANNUAL_FINANCIAL_STATUS } from 'api/mutations/tenantMutations';

export default function CompanyAnnualFinancialStatusModal({
	annualFinancialStatus,
	companyId,
	onClose,
}) {
	const { notify } = useSnackbar();
	const year = new Date().getFullYear();
	const years = dropdownYears(null, year);

	const reviewedYears =
		annualFinancialStatus?.length > 0
			? annualFinancialStatus.map(status => {
					if (status.readyForReview) return status.reviewedYear;
				})
			: null;

	const previousYear = () => {
		if (reviewedYears?.some(el => el === year - 1)) {
			return null;
		}

		return year - 1;
	};

	const [
		addAnnualFinancialSatus,
		{
			loading: addAnnualFinancialSatusLoading,
			error: addAnnualFinancialSatusError,
		},
	] = useMutation(ADD_ANNUAL_FINANCIAL_STATUS, {
		refetchQueries: ['findOneTenant'],
	});

	return (
		<ModalLayout
			title="Sett status for årsregnskap"
			onClose={onClose}
			maxWidth="800px"
		>
			<ErrorMessage errors={[addAnnualFinancialSatusError]} />

			<Form
				isLoading={addAnnualFinancialSatusLoading}
				isDisabled={addAnnualFinancialSatusLoading}
				onSubmit={async variables => {
					const year = variables.year;
					try {
						const { data } = await addAnnualFinancialSatus({
							variables: {
								_id: companyId,
								year,
							},
						});

						if (data?.added) {
							notify(
								`Regnskap ${year} er nå klart for gjennomgang`
							);
							onClose();
						}
					} catch (err) {
						console.error(err);
					}
				}}
			>
				<AutocompleteField
					name="year"
					label="Velg år"
					required={true}
					value={previousYear()}
					options={years.map(year => {
						return {
							value: year.value,
							label: year.label,
							disabled: reviewedYears?.some(
								el => el === year.value
							),
						};
					})}
				/>

				<p>Du kan kun sette årsregnskapet klart en gang per år.</p>

				<SubmitButton variant="contained">Sett klar</SubmitButton>
			</Form>
		</ModalLayout>
	);
}

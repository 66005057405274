import { Fragment } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import { formatDate } from 'utils/dates';

import ErrorMessage from 'components/ErrorMessage';
import { StyledListWithA } from 'components/MaterialList';

import { ContractStatus } from 'web/components/panels/CompanyContractsPanel';

import { PAGINATE_COMPANY_CONTRACTS } from 'api/queries/companyContractQueries';

const Wrapper = styled.div`
	margin: 30px 0 20px 0;
`;

const Panel = styled.div`
	background-color: ${p => p.theme.palette.background.default};
	border: 1px solid ${p => p.theme.palette.background.paper};
	padding: 20px;
	margin-bottom: 20px;
`;

const Title = styled.h3`
	margin: 0 0 20px 0;
`;

const Text = styled.div`
	color: ${p => p.theme.palette.status.subdued};
	font-size: 0.9rem;
	margin-top: 5px;
`;

const TextWrapper = styled.div`
	font-size: 14px;
	text-align: center;
`;

export default function FolderCompanyContracts({ companyId, parentName }) {
	if (!parentName || parentName !== 'Oppdragsavtaler') return null;

	const {
		data: { contracts = { count: 0, items: [] } } = {
			contracts: { count: 0, items: [] },
		},
		error,
	} = useQuery(PAGINATE_COMPANY_CONTRACTS, {
		variables: {
			companyId,
			isSentToSigning: true,
			orderBy: 'createdAt',
			order: -1,
		},
	});

	return (
		<Wrapper>
			<ErrorMessage errors={[error]} />

			<Panel>
				<Title>Oppdragsavtaler</Title>

				{contracts?.items?.length > 0 ? (
					<StyledListWithA>
						{contracts.items.map(
							({
								_id,
								typeLabel,
								isSentToSigning,
								isFullySigned,
								createdAt,
								createdByDisplayName,
								updatedAt,
								updatedByDisplayName,
							}) => {
								const createdDate = formatDate(
									createdAt,
									'DD.MM.YY'
								);

								return (
									<Fragment key={_id}>
										<ListItem
											secondaryAction={
												<TextWrapper>
													<div>{createdDate}</div>

													<ContractStatus
														isSentToSigning={
															isSentToSigning
														}
														isFullySigned={
															isFullySigned
														}
													/>
												</TextWrapper>
											}
										>
											<ListItemText>
												<a
													href={`${process.env.REACT_APP_CONTRACT_SIGN_URL}/avtaler/${_id}`}
													target="_blank"
													rel="noreferrer"
												>
													{typeLabel}
												</a>

												<Text>
													Sist oppdatert{' '}
													{formatDate(
														updatedAt || createdAt,
														'DD.MM.YY [kl] HH:mm'
													)}{' '}
													av{' '}
													{updatedByDisplayName ||
														createdByDisplayName}
												</Text>
											</ListItemText>
										</ListItem>
										<Divider component="li" />
									</Fragment>
								);
							}
						)}
					</StyledListWithA>
				) : (
					<div>
						Bedriften har ingen digitale oppdragsavtaler.
						<br /> Se nedenfor for evt. manuelt opplastede avtaler
						(pdf).
					</div>
				)}
			</Panel>
		</Wrapper>
	);
}

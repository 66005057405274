import { Plugins } from '@capacitor/core';
import { saveAs } from 'file-saver';

const { Browser, Device } = Plugins;

// TODO: Update when we drop the app
export async function download(url, name) {
	const info = await Device.getInfo();

	if (info.platform === 'ios') {
		console.info('Capacitor browser extension');
		await Browser.open({ url });
	} else if (typeof cordova !== 'undefined') {
		cordova.InAppBrowser.open(url, '_system');
	} else {
		saveAs(await (await fetch(url)).blob(), name);
	}
}

export async function upload(url, file, headers) {
	return await fetch(url, {
		method: 'PUT',
		body: file,
		headers,
	});
}

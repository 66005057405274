import { createRoot } from 'react-dom/client';

import WebProviders from 'web/contexts/WebContexts';

import { useUser } from 'web/contexts/UserContext';

import UnauthorizedApp from 'web/screens/UnauthorizedApp';

import AdminRoutes from 'web/AdminRoutes';
import AccountantRoutes from 'web/AccountantRoutes';
import AuditorRoutes from 'web/AuditorRoutes';
import UserRoutes from 'web/UserRoutes';

import Loading from 'components/Loading';

//import 'web/styles/reset.css';

function App() {
	const { user, userHasRole, userLoading } = useUser();

	if (userLoading) {
		return <Loading />;
	}

	if (user && user.role) {
		return (
			<>
				{userHasRole('admin') && <AdminRoutes />}
				{userHasRole('accountant') && <AccountantRoutes />}
				{userHasRole('auditor') && <AuditorRoutes />}
				{userHasRole('user') && <UserRoutes />}
				{userHasRole('silent') && (
					<div style={{ textAlign: 'center', padding: '40px' }}>
						<h1>Denne brukeren har ingen tilganger</h1>

						<p>
							Vennligst kontakt Falstads Regnskapsservice AS
							dersom dette er feil.
						</p>
					</div>
				)}
			</>
		);
	}

	return <UnauthorizedApp />;
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	<WebProviders>
		<App />
	</WebProviders>
);

import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import useSortOrder from 'hooks/useSortOrder';

import NotFound from 'web/screens/NotFound';

import { InlineLoading } from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';

import DesktopLayout, { Content, NarrowContent } from 'web/components/Layout';
import Document from 'web/components/views/Document';
import FileBrowser from 'web/components/views/FileBrowser';

import { GET_ONE_COMPANY } from 'api/queries/tenantQueries';
import { GET_COMPANY_DOCUMENTS } from 'api/queries/documentQueries';

export default function CompaniesDocumentsSingle() {
	let { companyId, documentNodeId } = useParams();

	const [includeHidden, setIncludeHidden] = useState(false);
	const [sort, setSort] = useSortOrder('createdAt', -1);

	const {
		data: { company = null } = { company: {} },
		loading: companyLoading,
		error: companyError,
	} = useQuery(GET_ONE_COMPANY, {
		variables: {
			_id: companyId,
		},
		errorPolicy: 'all',
	});

	const {
		data: {
			documentNode,
			parentDocumentNodes = [],
			documentNodes = { items: [], count: 0 },
		} = {},
		loading: documentNodesLoading,
		error: documentNodesError,
	} = useQuery(GET_COMPANY_DOCUMENTS, {
		fetchPolicy: 'cache-and-network',
		variables: {
			tenantId: companyId,
			parentId: documentNodeId,
			order: sort.order,
			orderBy: sort.orderBy,
			includeHidden,
		},
	});

	if (!company) {
		return (
			<NotFound
				label="Bedrift ikke funnet"
				title="Denne bedriften ble ikke funnet!"
			/>
		);
	}

	return (
		<DesktopLayout
			loading={companyLoading}
			breadcrumbs={[
				{
					to: '/bedrifter/',
					label: 'Bedrifter',
				},
				{
					to: `/bedrifter/${company?._id}/`,
					label: company?.name,
				},
				...parentDocumentNodes.map(parent => ({
					to: `/bedrifter/${company?._id}/${parent?._id}/`,
					label: parent?.name,
				})),
				{
					label: documentNode?.name ?? '...',
				},
			]}
			title={`${documentNode?.type === 'file' ? 'Fil: ' : 'Mappe: '}${documentNode?.name}`}
		>
			<ErrorMessage errors={[companyError]} />

			<CompanyDocumentsFileBrowser
				documentNode={documentNode}
				documentNodes={documentNodes}
				loading={documentNodesLoading}
				error={documentNodesError}
				includeHidden={includeHidden}
				setIncludeHidden={setIncludeHidden}
				sort={sort}
				setSort={setSort}
			/>
		</DesktopLayout>
	);
}

function CompanyDocumentsFileBrowser({
	documentNode,
	documentNodes,
	includeHidden,
	setIncludeHidden,
	sort,
	setSort,
	loading = false,
	error = null,
}) {
	if (loading) {
		return (
			<Content>
				<InlineLoading />
			</Content>
		);
	}

	if (error) {
		return (
			<Content>
				<ErrorMessage errors={error} />
			</Content>
		);
	}

	if (documentNode.type === 'file') {
		return (
			<NarrowContent>
				<Document documentNode={documentNode} />
			</NarrowContent>
		);
	}

	return (
		<Content>
			<FileBrowser
				documents={documentNodes}
				parentId={documentNode._id}
				parentName={documentNode.name}
				tenantId={documentNode.tenantId}
				linkBase={`/bedrifter/${documentNode.tenantId}/`}
				sort={sort}
				setNewOrder={setSort}
				includeHidden={includeHidden}
				setIncludeHidden={setIncludeHidden}
			/>
		</Content>
	);
}

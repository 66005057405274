import { Grid, GridItem } from 'web/components/company/GridLayout';

import CompanyAnnualFinancialStautsPanel from 'web/components/panels/CompanyAnnualFinancialStatusPanel';
import CompanyRisksPanel from 'web/components/panels/CompanyRisksPanel';
import CompanyControlsPanel from 'web/components/panels/CompanyControlsPanel';
import CompanyRoutinesPanel from 'web/components/panels/CompanyRoutinesPanel';

export default function FormsTab({ company }) {
	if (!company) return null;

	return (
		<Grid>
			<GridItem>
				<CompanyAnnualFinancialStautsPanel
					companyId={company._id}
					annualFinancialStatus={company?.annualFinancialStatus}
				/>
			</GridItem>

			<GridItem>
				<CompanyRisksPanel companyId={company._id} />
			</GridItem>

			<GridItem>
				<CompanyControlsPanel companyId={company._id} />
			</GridItem>

			<GridItem>
				<CompanyRoutinesPanel companyId={company._id} />
			</GridItem>
		</Grid>
	);
}

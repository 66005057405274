import styled from 'styled-components';

import AppFilters from 'components/filters/AppFilters';
import AppActiveFilters from 'components/filters/AppActiveFilters';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 20px;
	margin-bottom: 20px;
`;

export default function AppFiltersWithActiveState({ displayFilters = [] }) {
	if (!displayFilters || displayFilters.length === 0) {
		throw new Error('At least one displayFilter is required');
	}

	return (
		<Wrapper>
			<AppFilters displayFilters={displayFilters} />

			<AppActiveFilters displayFilters={displayFilters} />
		</Wrapper>
	);
}

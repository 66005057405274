import { gql } from '@apollo/client';

export const GET_COMPANY_ROUTINE_TYPES = gql`
	query getCompanyRoutineTypes {
		routineTypes: getCompanyRoutineTypes {
			value
			label
			defaultFolderPlacements
		}
	}
`;

export const FIND_ONE_COMPANY_ROUTINE = gql`
	query findOneCompanyRoutine($_id: ID!) {
		routine: findOneCompanyRoutine(_id: $_id) {
			_id
			companyId
			name
			folderPlacements
			fields {
				type
				name
				label
				description
				value
			}
			revision {
				date
				by
				byDisplayName
			}
			needsRevision
		}
	}
`;

export const GET_COMPANY_ROUTINES = gql`
	query getCompanyRoutines($companyId: ID!, $folderId: String) {
		routines: getCompanyRoutines(
			companyId: $companyId
			folderId: $folderId
		) {
			_id
			name
			type
			folderPlacements
			fields {
				type
				name
				label
				value
			}
			revision {
				date
				by
				byDisplayName
			}
			needsRevision
		}
	}
`;

import { useQuery } from '@apollo/client';

import { useAppFilters } from 'web/contexts/AppFiltersContext';

import Filter from 'components/filters/Filter';

import { PAGINATE_AUDITING_COMPANIES } from 'api/queries/auditingCompanyQueries';

const allOption = { value: '', label: 'Alle revisorfirmaer' };

export default function AuditingCompanyFilter() {
	const { auditingCompany, setAuditingCompany } = useAppFilters();

	const {
		data: { auditingCompanies = { count: 0, items: [] } } = {
			auditingCompanies: { count: 0, items: [] },
		},
		loading,
	} = useQuery(PAGINATE_AUDITING_COMPANIES, {
		variables: {
			order: 1,
			orderBy: 'name',
			limit: 100,
		},
	});

	return (
		<Filter
			name="auditing-companies-filter"
			label="Vis revisorfirmaer"
			value={auditingCompany || allOption}
			options={[
				allOption,
				...auditingCompanies.items.map(company => ({
					value: company._id,
					label: company.name,
				})),
			]}
			onChange={setAuditingCompany}
			loading={loading}
		/>
	);
}

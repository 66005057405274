import { useState, createContext, useContext } from 'react';

import useLocalStorage from 'hooks/useLocalStorage';

const currentDate = new Date();
const currentMonth = currentDate.getMonth();
const currentYear = currentDate.getFullYear();

// toLocaleString('nb-NO', { month: "long" }) display the month name in lowercase.
const monthLabels = {
	0: 'Januar',
	1: 'Februar',
	2: 'Mars',
	3: 'April',
	4: 'Mai',
	5: 'Juni',
	6: 'Juli',
	7: 'August',
	8: 'September',
	9: 'Oktober',
	10: 'November',
	11: 'Desember',
};

export const AppFiltersContext = createContext();

export function useAppFilters() {
	const context = useContext(AppFiltersContext);

	if (context === undefined) {
		throw new Error(
			'useApplFilters must be used within a AppFiltersProvider'
		);
	}

	return context;
}

export default function AppFiltersProvider({ children }) {
	const [search, setSearch] = useState('');
	// We dont't save the current year and month in local storage because we want to reset the filters when the user reloads the page or logs out.
	const [year, setYear] = useState({
		value: currentYear,
		label: currentYear.toString(),
	});

	const [month, setMonth] = useState({
		value: currentMonth,
		label: monthLabels[currentMonth],
	});

	const [task, setTask] = useLocalStorage('falstad-task-filter-v1');

	const [department, setDepartment] = useLocalStorage(
		'falstad-department-filter-v1'
	);

	const [accountant, setAccountant] = useLocalStorage(
		'falstad-web-accountant-filter-v1'
	);

	const [accountantRole, setAccountantRole] = useLocalStorage(
		'falstad-accountant-role-filter-v1'
	);

	const [userRole, setUserRole] = useLocalStorage(
		'falstad-user-role-filter-v1'
	);

	const [company, setCompany] = useLocalStorage('falstad-company-filter-v1');

	const [auditingCompany, setAuditingCompany] = useLocalStorage(
		'falstad-auditing-company-filter-v1'
	);

	const [accountType, setAccountType] = useLocalStorage(
		'falstad-account-type-filter-v1'
	);

	return (
		<AppFiltersContext.Provider
			value={{
				search,
				setSearch,
				year,
				setYear,
				month,
				setMonth,
				task,
				setTask,
				department,
				setDepartment,
				accountant,
				setAccountant,
				accountantRole,
				userRole,
				setUserRole,
				setAccountantRole,
				company,
				setCompany,
				auditingCompany,
				setAuditingCompany,
				accountType,
				setAccountType,
			}}
		>
			{children}
		</AppFiltersContext.Provider>
	);
}

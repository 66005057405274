import { gql } from '@apollo/client';

export const GET_VIABLE_PARENTS = gql`
	query paginateViableParentDocumentNodes(
		$tenantId: ID!
		$documentNodeId: ID!
	) {
		documentNodes: paginateViableParentDocumentNodes(
			tenantId: $tenantId
			documentNodeId: $documentNodeId
			orderBy: "name"
			order: 1
		) {
			count
			items {
				_id
				name
				createdBy: createdByDisplayName
				createdAt
				type
			}
		}
	}
`;

export const GET_DOCUMENT_SEARCH_RESULT = gql`
	query paginateDocumentSearch(
		$search: String
		$tenantId: ID!
		$signedDocuments: Boolean
		$signingDocuments: Boolean
		$untouchedFile: Boolean
	) {
		searchResult: paginateDocumentSearch(
			search: $search
			tenantId: $tenantId
			signedDocuments: $signedDocuments
			signingDocuments: $signingDocuments
			untouchedFile: $untouchedFile
		) {
			items {
				_id
				name
				type
				createdAt
				createdByDisplayName
				isSigning
				isSigningComplete
				locked
				filesCount
				signedDocuments {
					_id
					name
				}
				unsignedDocuments {
					_id
					name
				}
				signeesSigned
				signeesWaiting
				parentId
				seenByCurrentUser
			}
		}
	}
`;

export const GET_ALL_DOCUMENTS_FOR_TENANT = gql`
	query getAllDocumentsForTenant($tenantId: ID!) {
		documents: getAllDocumentsForTenant(tenantId: $tenantId) {
			_id
			name
			type
			parentId
			contentType
			createdAt
			createdByDisplayName
			updatedAt
			updatedByDisplayName
			isSigningComplete
		}
	}
`;

export const GET_COMPANY_DOCUMENTS = gql`
	query paginateDocumentNodes(
		$tenantId: ID!
		$parentId: ID!
		$order: Int
		$orderBy: String
		$includeHidden: Boolean
	) {
		documentNodes: paginateDocumentNodes(
			tenantId: $tenantId
			parentId: $parentId
			order: $order
			orderBy: $orderBy
			includeHidden: $includeHidden
		) {
			items {
				_id
				name
				type
				parentId
				tenantId
				date
				contentType
				size
				isSigning
				isSigningComplete
				signedPDFGenerated
				status
				isArchived
				isHidden
				createdBy
				updatedAt
				createdAt
				createdByDisplayName
				locked
				signedDocuments {
					_id
					name
				}
				unsignedDocuments {
					_id
					name
				}
				filesCount
				signeesSigned
				signeesWaiting
				order
				seenByCurrentUser
			}
		}
		documentNode: findOneDocumentNode(_id: $parentId) {
			_id
			name
			type
			parentId
			parent {
				name
			}
			tenantId
			date
			contentType
			size
			isSigning
			isSigningComplete
			manuallySigned
			signeesSigned
			signedPDFGenerated
			status
			isArchived
			isHidden
			createdBy
			updatedAt
			createdAt
			locked
			seenByCurrentUser
		}
		parentDocumentNodes: getDocumentParentHierarchyAsList(
			documentNodeId: $parentId
			tenantId: $tenantId
		) {
			_id
			name
			type
			parentId
			tenantId
			date
			contentType
			size
			isSigning
			isSigningComplete
			signedPDFGenerated
			status
			isArchived
			createdBy
			updatedAt
			createdAt
			locked
			seenByCurrentUser
		}
	}
`;

export const GET_COMPANY_FOLDERS = gql`
	query getFoldersForTenant($_id: ID!, $tenantId: ID!) {
		folder: getFoldersForTenant(_id: $_id, tenantId: $tenantId) {
			currentFolder {
				_id
				name
				parentId
			}
			subFolders {
				_id
				name
			}
		}
	}
`;

export const QUERY_DOCUMENT_NODES = gql`
	query documentNodes(
		$tenantId: ID!
		$documentId: ID!
		$includeHidden: Boolean
		$orderBy: String
		$order: Int
	) {
		documentNode: findOneDocumentNode(_id: $documentId) {
			_id
			name
			parentId
			type
			manuallySigned
			isSigning
			isSigningComplete
			contentType
			tenantId
			isArchived
			isHidden
			signedPDFGenerated
			locked
			parent {
				name
			}
			seenByCurrentUser
		}
		documentNodeChildren: paginateDocumentNodes(
			parentId: $documentId
			tenantId: $tenantId
			includeHidden: $includeHidden
			order: $order
			orderBy: $orderBy
		) {
			count
			items {
				_id
				name
				type
				parentId
				createdAt
				isSigning
				isSigningComplete
				createdBy: createdByDisplayName
				tenantId
				isArchived
				isHidden
				locked
				seenByCurrentUser
			}
		}
		lastVisitedDocumentNodes: getLastVisitedDocuments(tenantId: $tenantId) {
			_id
			name
			type
			parentId
			createdAt
			isSigning
			isSigningComplete
			createdBy: createdByDisplayName
			tenantId
			isArchived
			locked
		}
	}
`;

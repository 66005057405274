import { Fragment } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import { useUser } from 'web/contexts/UserContext';

import ErrorMessage from 'components/ErrorMessage';
import { UnderlineLink } from 'web/components/Link';

import { FIND_HELP_PAGE } from 'api/queries/helpPageQueries';
import { GET_FILES } from 'api/queries/fileQueries';

export const Section = styled.section`
	font-size: 1.2rem;
	line-height: 1.5rem;
	margin-bottom: 40px;

	h2 {
		margin-top: 12px;
	}
`;

export const Title = styled.h1`
	margin: 0 0 24px 0;
	display: flex;
	align-items: center;
	gap: 20px;
	line-height: 1.2;
`;

const EditLink = styled(UnderlineLink)`
	font-size: 0.5em;
`;

export default function HelpSingle() {
	const { helpPageId } = useParams();
	const { userHasMinRole } = useUser();

	const { data: { helpPage } = { helpPage: null }, error: helpPageError } =
		useQuery(FIND_HELP_PAGE, {
			variables: {
				_id: helpPageId,
			},
		});

	const { data: { files = [] } = { files: [] }, error: filesError } =
		useQuery(GET_FILES, { variables: { docId: helpPageId } });

	return (
		<article>
			<ErrorMessage errors={[helpPageError, filesError]} />

			<Title>
				<div>{helpPage?.name}</div>

				{userHasMinRole('admin') && (
					<EditLink to={`/hjelpesider/${helpPageId}/rediger/`}>
						(rediger)
					</EditLink>
				)}
			</Title>

			<Section>
				<List>
					{files &&
						files.map(file => (
							<Fragment key={file._id}>
								<ListItem dense={true} divider={true}>
									<ListItemButton
										component="a"
										href={file.url}
										title={`Last ned ${file.fileName}`}
										target="_blank"
										rel="noreferrer"
									>
										<ListItemIcon>
											<AttachFileIcon fontSize="small" />
										</ListItemIcon>

										<ListItemText primary={file.fileName} />
									</ListItemButton>
								</ListItem>
							</Fragment>
						))}
				</List>
			</Section>
		</article>
	);
}

import { useQuery, useMutation } from '@apollo/client';

import ModalLayout from 'components/modals/ModalLayout';
import ErrorAlert from 'components/ErrorAlert';

import Form from 'components/form/Form';
import AutocompleteField from 'components/form/AutocompleteField';
import SubmitButton from 'components/form/SubmitButton';

import { GET_COMPANY_ROUTINE_TYPES } from 'api/queries/companyRoutineQueries';
import { INSERT_ONE_COMPANY_ROUTINE } from 'api/mutations/companyRoutineMutations';

export default function CompanyRoutinesCreateModal({
	companyId,
	activeRoutines,
	onClose,
}) {
	const {
		data: { routineTypes } = { routineTypes: [] },
		loading: getCompanyRoutineTypesLoading,
		error: getCompanyRoutineTypesError,
	} = useQuery(GET_COMPANY_ROUTINE_TYPES);

	const [
		createRoutine,
		{ loading: createRoutineLoading, error: createRoutineError },
	] = useMutation(INSERT_ONE_COMPANY_ROUTINE, {
		refetchQueries: ['getCompanyRoutines'],
	});

	return (
		<ModalLayout
			title="Opprett ny rutine"
			onClose={onClose}
			maxWidth="800px"
		>
			<ErrorAlert error={getCompanyRoutineTypesError} />
			<ErrorAlert error={createRoutineError} />

			<Form
				isLoading={
					getCompanyRoutineTypesLoading || createRoutineLoading
				}
				isDisabled={
					getCompanyRoutineTypesLoading || createRoutineLoading
				}
				onSubmit={async ({ routineType }) => {
					try {
						const { data } = await createRoutine({
							variables: {
								companyId,
								type: routineType,
							},
						});

						if (data) {
							onClose();
						}
					} catch (e) {
						console.warn(e);
					}
				}}
			>
				{routineTypes && (
					<AutocompleteField
						label="Velg type rutine"
						name="routineType"
						options={routineTypes.map(routine => {
							return {
								value: routine.value,
								label: routine.label,
								disabled: activeRoutines.some(
									el => el.type === routine.value
								),
							};
						})}
						required
						multiple={false}
					/>
				)}

				<p>Du kan kun opprette en av hver type rutine.</p>

				<SubmitButton variant="contained">Opprett rutine</SubmitButton>
			</Form>
		</ModalLayout>
	);
}

import { useUser } from 'web/contexts/UserContext';
import { useAppFilters } from 'web/contexts/AppFiltersContext';

import Filter from 'components/filters/Filter';

const allOption = { value: '', label: 'Aktive bedrifter' };

export default function CompanyFilter({ size = 'medium' }) {
	const { userHasMinRole } = useUser();
	const { company, setCompany } = useAppFilters();

	return (
		<Filter
			name="companies-filter"
			label="Vis bedrifter"
			value={company ?? allOption}
			options={[
				allOption,
				{
					value: 'favorites',
					label: 'Favorittbedrifter',
				},
				...(userHasMinRole('admin')
					? [
							{
								value: 'inactive',
								label: 'Inaktive bedrifter',
							},
					  ]
					: []),
			]}
			onChange={setCompany}
			size={size}
		/>
	);
}

import { useQuery } from '@apollo/client';

import ErrorAlert from 'components/ErrorAlert';

import Widget from 'web/components/widgets/Widget';
import DocumentsList from 'web/components/widgets/components/lists/DocumentsList';
import ContractsList from 'web/components/widgets/components/lists/ContractsList';

import { COMPANY_DOCUMENTS_WITH_ACTIVE_SIGNING_REQUESTS } from 'api/queries/widgetQueries';
import { COMPANY_CONTRACTS_MISSING_SIGNATURES } from 'api/queries/widgetQueries';

export default function MySignatureRequestsWidget() {
	const {
		data: { documents } = { documents: [] },
		error: documentsError,
		loading: documentsLoading,
	} = useQuery(COMPANY_DOCUMENTS_WITH_ACTIVE_SIGNING_REQUESTS, {
		variables: {
			showOnlyMyRequests: true,
		},
	});

	const {
		data: { contracts } = { contracts: [] },
		error: contractsError,
		loading: contractsLoading,
	} = useQuery(COMPANY_CONTRACTS_MISSING_SIGNATURES, {
		variables: {
			showOnlyMyRequests: true,
		},
	});

	return (
		<Widget
			title="Mine aktive signaturerforespørsler"
			titleIcon="file-signature"
			loading={documentsLoading || contractsLoading}
		>
			<ErrorAlert error={documentsError} />
			<DocumentsList documents={documents} />

			<ErrorAlert error={contractsError} />
			<ContractsList contracts={contracts} />
		</Widget>
	);
}

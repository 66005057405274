import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';

import { useSnackbar } from 'web/contexts/SnackbarContext';
import { useConfirmDialog } from 'components/contexts/ConfirmContext';

import Icon from 'components/Icon';
import {
	StyledList,
	StyledListItemText,
	EmptyList,
} from 'components/MaterialList';
import ErrorMessage from 'components/ErrorMessage';

import TextField from 'components/form/TextField';

import Panel from 'web/components/panels/Panel';
import Link from 'web/components/Link';

import AddOwnerModal from 'web/screens/modals/AddCompanyOwnerModal';

import {
	REMOVE_OWNER_FROM_COMPANY,
	UPDATE_OWNER_ON_COMPANY,
} from 'api/mutations/tenantMutations';

const OwnerNote = styled.div`
	margin-top: 5px;
`;

const StyledEditButton = styled.button`
	background: transparent;
	border: none;
	padding: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
	transition: all 0.5s;
	opacity: 0.3;
	margin-right: 12px;
	&:hover {
		opacity: 1;
	}
`;

const StyledIcon = styled(Icon)`
	min-width: 24px;
	i {
		min-width: 24px;
	}
`;

const StyledAddIcon = styled(Icon)`
	font-size: 14px !important;
	min-width: 0;
`;

const AddButtonWrapper = styled.div`
	text-align: right;
`;

const ListItemTextInner = styled.div`
	display: block;
	width: calc(100% - 50px);
`;

export default function CompanyOwners({ companyId, owners }) {
	const [openModal, setOpenModal] = useState();
	const [error, setError] = useState(false);

	return (
		<Panel
			title="Reelle rettighetshavere"
			action={
				<AddButtonWrapper>
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							setOpenModal(true);
						}}
						endIcon={<StyledAddIcon icon="plus" />}
					>
						Legg til
					</Button>
				</AddButtonWrapper>
			}
		>
			<ErrorMessage errors={error} />

			{owners && (
				<StyledList>
					{owners.map(owner => (
						<OwnerDetails
							key={owner.user._id}
							owner={owner}
							companyId={companyId}
							setError={setError}
						/>
					))}
				</StyledList>
			)}

			{(!owners || owners.length === 0) && (
				<EmptyList>
					Bedriften har ingen reelle rettighetshavere
				</EmptyList>
			)}

			{openModal &&
				createPortal(
					<AddOwnerModal
						companyId={companyId}
						onClose={() => setOpenModal(false)}
					/>,
					document.getElementById('modal-root')
				)}
		</Panel>
	);
}

function OwnerDetails({ owner, companyId, setError }) {
	const { verify } = useConfirmDialog();

	if (!owner) return null;

	const { user, note } = owner;

	if (!user) return null;

	const { notify } = useSnackbar();
	const [isEditing, setIsEditing] = useState(false);
	const [noteValue, setNoteValue] = useState(note || '');

	const [
		updateOwner,
		{ loading: updateOwnerLoading, error: updateOwnerError },
	] = useMutation(UPDATE_OWNER_ON_COMPANY, {
		refetchQueries: ['findOneTenant'],
	});

	const [
		removeOwner,
		{ loading: removeOwnerLoading, error: removeOwnerError },
	] = useMutation(REMOVE_OWNER_FROM_COMPANY, {
		refetchQueries: ['findOneTenant'],
	});

	useEffect(() => {
		setError(updateOwnerError || removeOwnerError);
	}, [updateOwnerError, removeOwnerError]);

	return (
		<>
			<ListItem
				secondaryAction={
					<>
						{isEditing && (
							<Button
								edge="end"
								size="small"
								variant="outlined"
								color="primary"
								type="button"
								title="Lagre"
								disabled={updateOwnerLoading}
								onClick={async () => {
									try {
										const { data } = await updateOwner({
											variables: {
												_id: companyId,
												userId: user._id,
												note: noteValue,
											},
										});

										if (data && data.updated) {
											notify(
												'Rettighetshaver ble oppdatert'
											);
										}

										setIsEditing(false);
									} catch (err) {
										console.error(err);
									}
								}}
							>
								Lagre
							</Button>
						)}
						{!isEditing && (
							<Tooltip
								title="Fjern bruker fra bedriften"
								placement="left"
							>
								<IconButton
									edge="end"
									aria-label="Fjern reell rettighetshaver"
									disabled={removeOwnerLoading}
									onClick={async e => {
										try {
											e.preventDefault();

											if (
												!(await verify({
													title: 'Fjern bruker fra bedrift',
													text: 'Er du sikker på at du ønsker å fjerne brukeren fra bedriften? (Brukeren blir ikke slettet).',
												}))
											) {
												return;
											}

											const { data } = await removeOwner({
												variables: {
													_id: companyId,
													userId: user._id,
												},
											});

											if (data && data.removed) {
												notify(
													'Rettighetshaver ble fjernet'
												);
											}
										} catch (err) {
											console.error(err);
										}
									}}
								>
									<Icon
										icon="trash"
										size=""
										className="button-icon"
										solid
									/>
								</IconButton>
							</Tooltip>
						)}
					</>
				}
			>
				<StyledEditButton
					type="button"
					title={isEditing ? 'Avbryt' : 'Rediger'}
					onClick={() => {
						setIsEditing(!isEditing);
					}}
				>
					{!isEditing && <StyledIcon icon="pen" size="" solid />}
					{isEditing && <StyledIcon icon="close" size="" solid />}
				</StyledEditButton>
				<StyledListItemText
					primary={
						<ListItemTextInner>
							<Link to={`/brukere/${user._id}`}>{user.name}</Link>
							{isEditing && (
								<TextField
									size="small"
									name={`note-` + user._id}
									maxRows="5"
									multiline={true}
									value={noteValue}
									onChange={e => {
										setNoteValue(e.target.value);
									}}
									inputRef={input => input && input.focus()}
									onFocus={e =>
										e.currentTarget.setSelectionRange(
											e.currentTarget.value.length,
											e.currentTarget.value.length
										)
									}
									style={{ marginBottom: '0' }}
								/>
							)}
							{note && !isEditing && (
								<OwnerNote>{note}</OwnerNote>
							)}
						</ListItemTextInner>
					}
				/>
			</ListItem>
			<Divider component="li" />
		</>
	);
}

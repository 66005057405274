import { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { useQuery } from '@apollo/client';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

import { formatDate } from 'utils/dates';

import CompanyControlsCreateModal from 'web/screens/modals/CompanyControlsCreateModal';

import { StyledListWithA, EmptyList } from 'components/MaterialList';
import { CompanyControlStatusPill } from 'components/Pill';
import ErrorMessage from 'components/ErrorMessage';

import Panel from 'web/components/panels/Panel';

import { GET_COMPANY_CONTROLS } from 'api/queries/companyControlQueries';

const AddButtonWrapper = styled.div`
	text-align: right;
`;

const TextWrapper = styled.div`
	text-align: center;
`;

const Status = styled(CompanyControlStatusPill)`
	margin-top: 5px;
	min-width: 110px;
`;

const SubText = styled.div`
	color: ${p => p.theme.palette.status.subdued};
	font-size: 0.9rem;
	margin-top: 5px;
`;

export default function CompanyControlsPanel({ companyId }) {
	const [showCreateControlModal, setShowCreateControlModal] = useState(false);

	const {
		data: { controls } = { controls: [] },
		loading,
		error,
	} = useQuery(GET_COMPANY_CONTROLS, {
		variables: {
			companyId,
		},
	});

	if (loading) return null;

	return (
		<>
			<Panel
				title="Bedriftskontroller"
				action={
					<AddButtonWrapper>
						<Button
							size="medium"
							variant="contained"
							color="primary"
							endIcon={<AddIcon size="small" />}
							onClick={() => setShowCreateControlModal(true)}
						>
							Opprett ny
						</Button>
					</AddButtonWrapper>
				}
			>
				<ErrorMessage errors={error} />

				{controls.length > 0 ? (
					<StyledListWithA>
						{controls.map(({ _id, name, year, status }) => (
							<Fragment key={_id}>
								<ListItem
									secondaryAction={
										<TextWrapper>
											<div>{year}</div>

											<Status $statusCode={status.code}>
												{status.label}
											</Status>
										</TextWrapper>
									}
								>
									<ListItemText>
										<Link
											to={`/bedrifter/${companyId}/kontroller/${_id}/rediger/`}
										>
											{name}
										</Link>

										<SubText>
											Sist oppdatert{' '}
											{formatDate(
												status.updatedAt,
												'DD.MM.YY [kl] HH:mm'
											)}{' '}
											av {status.updatedByDisplayName}
										</SubText>
									</ListItemText>
								</ListItem>

								<Divider component="li" />
							</Fragment>
						))}
					</StyledListWithA>
				) : (
					<EmptyList>Bedriften har ingen kontrollskjema.</EmptyList>
				)}
			</Panel>

			{showCreateControlModal &&
				createPortal(
					<CompanyControlsCreateModal
						companyId={companyId}
						onClose={() => setShowCreateControlModal(false)}
					/>,
					document.getElementById('modal-root')
				)}
		</>
	);
}

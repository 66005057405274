import { gql } from '@apollo/client';

export const GET_COMPANY_RISKS = gql`
	query findCompanyRisks($companyId: ID!) {
		companyRisks: findCompanyRisks(companyId: $companyId) {
			_id
			name
			revision {
				date
				by
				byDisplayName
			}
			needsRevision
			totalScore
		}
	}
`;

export const GET_ONE_COMPANY_RISK = gql`
	query findOneCompanyRisk($_id: ID!) {
		companyRisk: findOneCompanyRisk(_id: $_id) {
			_id
			name
			companyId
			company {
				_id
				name
				businessCodes
				unAutenhicatedUsers(
					roles: [
						"innehaver"
						"dagligleder"
						"nestleder"
						"styreleder"
						"styremedlem"
					]
				) {
					_id
					name
				}
				unAutenhicatedOwners {
					_id
					name
				}
				pepUsers {
					_id
					name
				}
			}
			categories {
				_id
				type
				name
				questions {
					_id
					type
					label
					help
					requiredComment
					comment
					value
					choices {
						value
						label
					}
				}
			}
			calculatedScore
			totalEvaluation
			totalScore
			hasChanges
			revision {
				date
				by
				byDisplayName
			}
			needsRevision
		}
	}
`;

export const GET_CATEGORY_SCORE = gql`
	query getCategoryScore($categoryId: ID!) {
		categoryScore: getCategoryScore(categoryId: $categoryId)
	}
`;

export const GET_LATEST_COMPANY_RISK = gql`
	query getLatestCompanyRisk($companyId: ID!) {
		companyRisk: getLatestCompanyRisk(companyId: $companyId) {
			_id
			totalScore
			needsRevision
		}
	}
`;

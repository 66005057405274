import { useEffect } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { isDateValid } from 'utils/dates';

import { useForm } from 'components/form/Form';

const Wrapper = styled.div`
	margin-bottom: 16px;
`;

const Inner = styled.div`
	display: inline-block;
	position: relative;

	.MuiInputBase-input {
		cursor: pointer;
	}
`;

const ErrorText = styled.div`
	color: ${p => p.theme.palette.status.error};
`;

export default function DateField(props) {
	const { name, disabled, onChange = null, value, defaultValue } = props;
	const { registerField, updateField, values, isDisabled, errors } =
		useForm();

	const error = (errors && errors[name] && errors[name].label) || null;

	useEffect(() => {
		if (!registerField) return;

		registerField({ ...props, validate: isDateValid });
	}, []);

	return (
		<Wrapper>
			<Inner>
				<DatePicker
					format="DD/MM/YYYY"
					disableMaskedInput
					disabled={disabled || isDisabled}
					error={errors && !!errors[name]}
					value={dayjs(
						(values && values[name]) ||
							value ||
							defaultValue ||
							new Date()
					)}
					onChange={value => {
						if (!value) return;

						if (onChange) {
							onChange({
								name,
								value: value?.toDate() || '',
							});
							return;
						}
						updateField({
							name,
							value: value?.toDate() || '',
						});
					}}
					slotProps={{ textField: { variant: 'outlined' } }}
				/>
			</Inner>

			{!!error && <ErrorText>{error}</ErrorText>}
		</Wrapper>
	);
}

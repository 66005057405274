import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useWebApp } from 'web/contexts/WebContexts';

import Home from 'web/screens/Home';

import Documents from 'web/screens/user/Documents';
import ShareDocumentNode from 'web/screens/user/ShareDocumentNode';
import SendSigningRequest from 'web/screens/user/SendSigningRequest';

import UserProfile from 'web/screens/UserProfile';

import NotFound from 'web/screens/NotFound';

import RouteRedirect from 'web/RouteRedirect';

export default function UserRoutes() {
	const { data } = useWebApp();

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Home />} />

				<Route path="profil" element={<UserProfile />} />

				<Route path="dokumenter">
					<Route index element={<Documents />} />

					<Route path=":documentNodeId">
						<Route index element={<Documents />} />

						<Route
							path="signer"
							element={
								<SendSigningRequest
									companyId={
										data && data.tenant && data.tenant._id
									}
								/>
							}
						/>

						<Route
							path="del"
							element={
								<ShareDocumentNode
									companyId={
										data && data.tenant && data.tenant._id
									}
								/>
							}
						/>
					</Route>
				</Route>

				<Route path="404" element={<NotFound />} />

				<Route path="*" element={<RouteRedirect redirect="/404" />} />
			</Routes>
		</BrowserRouter>
	);
}

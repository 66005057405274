import { useQuery } from '@apollo/client';
import Stack from '@mui/material/Stack';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';

import ErrorMessage from 'components/ErrorMessage';
import AutocompleteField from './AutocompleteField';

import { useForm } from 'components/form/Form';
import CheckboxField, { CheckboxGroup } from 'components/form/CheckboxField';

import { GET_TENANT_USERS } from 'api/queries/tenantQueries';

export default function FileCreateNotificationsField({ tenantId }) {
	const { values } = useForm();

	const {
		data: { users } = { users: [] },
		loading,
		error,
	} = useQuery(GET_TENANT_USERS, {
		variables: { tenantId },
	});

	if (loading) {
		return null;
	}

	return (
		<div>
			<ErrorMessage errors={error} />

			<AutocompleteField
				name="users"
				label="Send notifikasjon til"
				options={users.map(user => {
					return {
						value: user._id,
						label: user.name,
						isDisabled: !user.email || !user.phone,
						tooltip: [
							<Stack
								key="1"
								direction="row"
								alignItems="center"
								gap={1}
							>
								<MailIcon />
								{user.email || 'Mangler e-post'}
							</Stack>,
							<Stack
								key="2"
								direction="row"
								alignItems="center"
								gap={1}
							>
								<PhoneIcon />
								{user.phone || 'Mangler telefon'}
							</Stack>,
						],
					};
				})}
				multiple
			/>

			{values['users'] && values['users'].length > 0 && (
				<CheckboxGroup>
					<CheckboxField name="notifyEmail" label="E-post" />

					<CheckboxField name="notifySMS" label="SMS" />

					<CheckboxField name="notifyPush" label="Pushvarsel" />
				</CheckboxGroup>
			)}
		</div>
	);
}

import { gql } from '@apollo/client';

export const INSERT_ONE_HELP_PAGE = gql`
	mutation insertOneHelpPage($name: String!) {
		newHelpPage: insertOneHelpPage(name: $name) {
			_id
			name
		}
	}
`;

export const UPDATE_HELP_PAGE = gql`
	mutation updateOneHelpPage($_id: ID!, $name: String!) {
		helpPage: updateOneHelpPage(_id: $_id, name: $name) {
			_id
			name
		}
	}
`;

export const DELETE_HELP_PAGE = gql`
	mutation deleteOneHelpPage($_id: ID!) {
		deleted: deleteOneHelpPage(_id: $_id)
	}
`;

import { useEffect, useState } from 'react';
import styled from 'styled-components';
import MUITextField from '@mui/material/TextField';

import { useForm } from 'components/form/Form';

import Icon from 'components/Icon';

const Wrapper = styled.div`
	margin-bottom: 16px;
	position: relative;
	cursor: pointer;

	.MuiFormControl-root {
		width: 100%;
	}
`;

const StyledFileInput = styled.input`
	position: absolute;
	left: 0;
	bottom: 0;
	top: 0;
	opacity: 0;
	width: 100%;
	cursor: pointer;
`;

const StyledIcon = styled(Icon)`
	position: absolute;
	right: 16px;
	top: 22px;
	pointer-events: none;
	font-size: 20px;
	text-align: right;
	overflow: visible;
`;

const Error = styled.div`
	background-color: ${p => p.theme.palette.status.error};
	color: ${p => p.theme.palette.text.white};
	margin-top: 10px;
	padding: 20px;
`;

export default function FileField({ maxSize = null, accept = null, ...props }) {
	const context = useForm();

	const { name, onChange, disabled } = props;
	const { registerField, updateField, isDisabled, errors } = context;

	const [currentValue, setCurrentValue] = useState('');

	const error = errors[name];

	useEffect(() => {
		registerField(props);
	});

	return (
		<Wrapper>
			<MUITextField
				{...props}
				disabled={disabled || isDisabled}
				error={!!errors[name]}
				inputProps={{
					'data-lpignore': 'true',
				}}
				value={currentValue || ''}
			/>

			<StyledFileInput
				name={name}
				type="file"
				data-lpignore="true"
				accept={accept}
				onChange={e => {
					const file = e.target.files[0];

					if (!file) {
						return;
					}

					if (!maxSize || (maxSize && file.size <= maxSize)) {
						setCurrentValue(file.name);

						updateField({
							name,
							value: file,
						});

						onChange && onChange(file, context);
					} else {
						setCurrentValue('');

						updateField({
							name,
							value: null,
							error: `Filen kan maksimalt være ${(
								maxSize / 1000000
							).toFixed(1)} MB`,
						});
					}
				}}
			/>

			<StyledIcon icon="plus-circle" />

			{!!error && <Error>{error}</Error>}
		</Wrapper>
	);
}

import { Fragment, useState } from 'react';
import { createPortal } from 'react-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { getFolderNameBySlug } from 'server/libs/defaultFolders';
import { pluralize } from 'utils/strings';
import { conjunctionFormat } from 'utils/arrays';
import { formatDate } from 'utils/dates';

import CompanyRoutinesCreateModal from 'web/screens/modals/CompanyRoutinesCreateModal';

import Panel from 'web/components/panels/Panel';
import { EmptyList } from 'components/MaterialList';
import { StyledListWithA } from 'components/MaterialList';
import ErrorMessage from 'components/ErrorMessage';
import ErrorAlert from 'components/ErrorAlert';

import { GET_COMPANY_ROUTINES } from 'api/queries/companyRoutineQueries';
import { DELETE_COMPANY_ROUTINE } from 'api/mutations/companyRoutineMutations';
import { useSnackbar } from 'web/contexts/SnackbarContext';
import { useConfirmDialog } from 'components/contexts/ConfirmContext';

const Text = styled.div`
	color: ${p => p.theme.palette.status.subdued};
	font-size: 0.9rem;
	margin-top: 5px;
`;

const Revision = styled.span`
	color: ${p =>
		p.needsRevision
			? p.theme.palette.status.error
			: p.theme.palette.status.success};
`;

const AddButtonWrapper = styled.div`
	text-align: right;
`;

export default function CompanyRoutinesPanel({ companyId }) {
	const { notify } = useSnackbar();
	const { verify } = useConfirmDialog();

	const [showCreateRoutineModal, setShowCreateRoutineModal] = useState(false);

	const {
		data,
		loading: getRoutineLoading,
		error: getRoutineError,
	} = useQuery(GET_COMPANY_ROUTINES, {
		variables: {
			companyId,
		},
	});

	const [deleteRoutine, { error: deleteRoutineError }] = useMutation(
		DELETE_COMPANY_ROUTINE,
		{ refetchQueries: ['getCompanyRoutines'] }
	);

	const handleDelete = async routine => {
		try {
			if (
				!(await verify({
					title: `Slett bedriftsrutine`,
					text: `Er du sikker på at du vil slette ${routine.name}?`,
				}))
			) {
				return;
			}

			const { data } = await deleteRoutine({
				variables: {
					_id: routine._id,
				},
			});

			if (data.deleted) {
				notify(`${routine.name} ble slettet`);
			}
		} catch (err) {
			console.error(err);
		}
	};

	if (getRoutineLoading) return null;

	return (
		<>
			<Panel
				title="Bedriftsrutiner"
				action={
					<AddButtonWrapper>
						<Button
							size="medium"
							variant="contained"
							color="primary"
							endIcon={<AddIcon size="small" />}
							onClick={() => setShowCreateRoutineModal(true)}
						>
							Opprett ny
						</Button>
					</AddButtonWrapper>
				}
			>
				<ErrorMessage errors={getRoutineError} />
				<ErrorAlert error={deleteRoutineError} />

				{data?.routines?.length > 0 ? (
					<StyledListWithA>
						{data.routines.map(
							({
								_id,
								name,
								folderPlacements,
								revision,
								needsRevision,
							}) => (
								<Fragment key={_id}>
									<ListItem
										secondaryAction={
											<IconButton
												edge="end"
												aria-label="Slett"
												role="button"
												onClick={() => {
													handleDelete({
														_id: _id,
														name: name,
													});
												}}
											>
												<DeleteIcon />
											</IconButton>
										}
									>
										<ListItemText>
											<Link
												to={`/bedrifter/${companyId}/rutiner/${_id}/rediger/`}
											>
												{name}
											</Link>

											{folderPlacements ? (
												<Text>
													<strong>
														{pluralize(
															'Mappeplassering: ',
															'Mappeplasseringer: ',
															Text
														)}
													</strong>

													<span>
														{conjunctionFormat(
															folderPlacements.map(
																slug =>
																	getFolderNameBySlug(
																		slug
																	)
															)
														)}
													</span>
												</Text>
											) : (
												<Text>
													Vises ikke i noen mappe
												</Text>
											)}

											{revision ? (
												<Text>
													<Revision
														$needsRevision={
															needsRevision
														}
													>
														Revidert{' '}
														{formatDate(
															revision.date,
															'DD.MM.YY'
														)}
													</Revision>{' '}
													av {revision.byDisplayName}
												</Text>
											) : null}
										</ListItemText>
									</ListItem>
									<Divider component="li" />
								</Fragment>
							)
						)}
					</StyledListWithA>
				) : (
					<EmptyList>Bedriften har ingen rutiner</EmptyList>
				)}
			</Panel>

			{showCreateRoutineModal &&
				createPortal(
					<CompanyRoutinesCreateModal
						companyId={companyId}
						activeRoutines={data?.routines ? data.routines : []}
						onClose={() => setShowCreateRoutineModal(false)}
					/>,
					document.getElementById('modal-root')
				)}
		</>
	);
}

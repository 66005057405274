import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import FileListWithUploadDialog from 'web/screens/dialogs/users/FileListWithUploadDialog';

import ErrorMessage from 'components/ErrorMessage';
import TextField from 'components/form/TextField';
import SubmitButton from 'components/form/SubmitButton';
import DeleteButton from 'components/buttons/DeleteButton';

import Form from 'components/form/Form';

import DesktopLayout, {
	NarrowContent,
	StickyAction,
} from 'web/components/Layout';

import { FIND_HELP_PAGE } from 'api/queries/helpPageQueries';
import {
	UPDATE_HELP_PAGE,
	DELETE_HELP_PAGE,
} from 'api/mutations/helpPageMutations';

export default function HelpPagesEdit() {
	const { helpPageId } = useParams();
	const { notify } = useSnackbar();

	const {
		data: { helpPage } = { helpPage: null },
		loading: helpPageLoading,
		error: helpPageError,
	} = useQuery(FIND_HELP_PAGE, {
		variables: {
			_id: helpPageId,
		},
	});

	const [
		updateHelpPage,
		{ loading: updateHelpPageLoading, error: updateHelpPageError },
	] = useMutation(UPDATE_HELP_PAGE);

	const [
		deleteHelpPage,
		{ loading: deleteHelpPageLoading, error: deleteHelpPageError },
	] = useMutation(DELETE_HELP_PAGE);

	return (
		<DesktopLayout
			breadcrumbs={[
				{
					to: '/hjelpesider/',
					label: 'Hjelpesider',
				},
				{
					label: 'Oppdater hjelpeside',
				},
			]}
		>
			<NarrowContent>
				<ErrorMessage
					errors={[
						helpPageError,
						updateHelpPageError,
						deleteHelpPageError,
					]}
				/>

				<Form
					key={helpPage && helpPage._id}
					values={helpPage}
					onSubmit={async variables => {
						const { data } = await updateHelpPage({
							variables,
						});

						if (data) {
							notify(`${data.helpPage.name} ble oppdatert!`);
						}
					}}
					isLoading={
						helpPageLoading ||
						updateHelpPageLoading ||
						deleteHelpPageLoading
					}
					isDisabled={
						helpPageLoading ||
						updateHelpPageLoading ||
						deleteHelpPageLoading
					}
				>
					<TextField name="name" label="Navn" required />

					<StickyAction>
						<DeleteButton
							disabled={deleteHelpPageLoading}
							onClick={async () => {
								const { data } = await deleteHelpPage({
									variables: {
										_id: helpPage._id,
									},
								});

								if (!data.deleted) {
									throw new Error(
										'Det oppstod en feil ved sletting!'
									);
								}
							}}
							redirect="/hjelpesider/"
						/>

						<SubmitButton
							variant="contained"
							size="large"
							icon={<SaveIcon />}
						>
							Oppdater
						</SubmitButton>
					</StickyAction>
				</Form>

				<FileListWithUploadDialog
					docId={helpPageId}
					uploadContext="helpPage"
				/>
			</NarrowContent>
		</DesktopLayout>
	);
}

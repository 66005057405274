import React, { Fragment, useState } from 'react';
import { createPortal } from 'react-dom';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';

import { useConfirmDialog } from 'components/contexts/ConfirmContext';
import { useSnackbar } from 'web/contexts/SnackbarContext';

import { formatDate } from 'utils/dates';

import CompanyAnnualFinancialStatusModal from 'web/screens/modals/CompanyAnnualFinancialStatusModal';

import { StyledList } from 'components/MaterialList';
import ErrorAlert from 'components/ErrorAlert';
import Panel from 'web/components/panels/Panel';

import { REMOVE_ANNUAL_FINANCIAL_STATUS } from 'api/mutations/tenantMutations';

const AddButtonWrapper = styled.div`
	text-align: right;
`;

const Text = styled.div`
	color: ${p => p.theme.palette.status.subdued};
	font-size: 0.9rem;
	margin-top: 5px;
`;

export const EmptyList = styled.div`
	background: white;
	padding: 16px;
	margin-bottom: 20px;
`;

export default function CompanyAnnualFinancialStautsPanel({
	companyId,
	annualFinancialStatus = null,
}) {
	const { notify } = useSnackbar();
	const { verify } = useConfirmDialog();

	const [showAddModal, setShowAddModal] = useState(false);

	const handleDeleteStatus = async year => {
		try {
			if (
				!(await verify({
					title: `Slette regnskapsstatus`,
					text: `Er du sikker på at du vil slette status for regnskapsåret ${year}?`,
				}))
			) {
				return;
			}

			const { data } = await removeAnnualFinancialSatus({
				variables: {
					_id: companyId,
					year,
				},
			});

			if (data.removed) {
				notify(`Regnskapsstatus ${year} ble slettet`);
			}
		} catch (err) {
			console.error(err);
		}
	};

	const [removeAnnualFinancialSatus, { loading, error }] = useMutation(
		REMOVE_ANNUAL_FINANCIAL_STATUS,
		{
			refetchQueries: ['findOneTenant'],
		}
	);

	return (
		<>
			<Panel
				title="Regnskapsstatus"
				action={
					<AddButtonWrapper>
						<Button
							endIcon={<CheckIcon size="small" />}
							size="medium"
							variant="contained"
							color="primary"
							onClick={() => {
								setShowAddModal(true);
							}}
							disabled={loading}
						>
							Sett klar
						</Button>
					</AddButtonWrapper>
				}
			>
				<ErrorAlert error={error} />

				{annualFinancialStatus && annualFinancialStatus.length > 0 ? (
					<StyledList>
						{annualFinancialStatus.map((status, index) => {
							return (
								<Fragment key={index}>
									<ListItem
										secondaryAction={
											<IconButton
												edge="end"
												aria-label="Slett"
												role="button"
												onClick={() => {
													handleDeleteStatus(
														status.reviewedYear
													);
												}}
											>
												<DeleteIcon />
											</IconButton>
										}
									>
										<ListItemText>
											Regnskapsår {status.reviewedYear}
											<Text>
												{status.notRelevant ? (
													<>
														Merket som{' '}
														<strong>
															ikke relevant
														</strong>{' '}
														av{' '}
													</>
												) : (
													<>
														Satt klar for
														gjennomgang av{' '}
													</>
												)}
												{status.reviewedByDisplayName}

												{' - '}
												{formatDate(
													status.reviewedAt,
													'DD.MM.YY [kl] HH:mm'
												)}
											</Text>
										</ListItemText>
									</ListItem>
									<Divider component="li" />
								</Fragment>
							);
						})}
					</StyledList>
				) : (
					<EmptyList>
						Det finnes ingen år med gjennomgåtte regnskap.
					</EmptyList>
				)}
			</Panel>

			{showAddModal &&
				createPortal(
					<CompanyAnnualFinancialStatusModal
						annualFinancialStatus={annualFinancialStatus}
						companyId={companyId}
						onClose={() => {
							setShowAddModal(false);
						}}
					/>,

					document.getElementById('modal-root')
				)}
		</>
	);
}

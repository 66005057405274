import styled from 'styled-components';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { InlineLoading } from 'components/Loading';

const FilterWrapper = styled.div`
	position: relative;
	min-width: ${p => p.$minWidth};
`;

export default function Filter({
	name,
	label,
	defaultValue = null,
	options,
	onChange,
	loading = false,
	disableClearable = true,
	noOptionsText = 'Ingen treff',
	minWidth = '200px',
	...props
}) {
	return (
		<FilterWrapper $minWidth={minWidth}>
			{loading ? (
				<InlineLoading />
			) : (
				<Autocomplete
					defaultValue={defaultValue}
					options={options}
					onChange={(e, option) => onChange(option)}
					name={name}
					renderInput={params => (
						<TextField {...params} label={label} />
					)}
					isOptionEqualToValue={(option, value) => {
						return option.value === value.value;
					}}
					loading={loading}
					disableClearable={disableClearable}
					noOptionsText={noOptionsText}
					{...props}
				/>
			)}
		</FilterWrapper>
	);
}

import styled from 'styled-components';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';

export const StyledList = styled(List)`
	padding: 0;
	margin-bottom: 20px;
`;

export const StyledListWithA = styled(List)`
	padding: 0;
	margin-bottom: 20px;

	a {
		color: inherit;
		&:hover {
			color: ${p => p.theme.palette.primary.main};
		}
	}
`;

export const EmptyList = styled.div`
	padding: 16px;
	margin-bottom: 20px;
`;

export const StyledListItemText = styled(ListItemText)`
	a {
		text-decoration: underline;
	}
`;

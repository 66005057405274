import { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import useSortOrder from 'hooks/useSortOrder';

import { useWebApp } from 'web/contexts/WebContexts';
import { useUser } from 'web/contexts/UserContext';
import { useAppFilters } from 'web/contexts/AppFiltersContext';

import CompanyAnnualFinancialStatusModal from 'web/screens/modals/CompanyAnnualFinancialStatusModal';

import ErrorMessage from 'components/ErrorMessage';
import { InlineLoading } from 'components/Loading';
import StatusIcon from 'components/StatusIcon';

import AppFilters from 'components/filters/AppFilters';

import DesktopLayout, {
	ArchiveHeader,
	ArchiveHeaderRow,
	Content,
} from 'web/components/Layout';
import SearchBar from 'web/components/NewSearchBar';
import AppActiveFilters from 'components/filters/AppActiveFilters';
import SortIcon from 'web/components/SortIcon';
import Table, { TableRow, TableCell } from 'web/components/Table';
import CreateFabButton from 'web/components/CreateFabButton';
import LoadMoreButton from 'web/components/LoadMoreButton';
import { StyledLink } from 'web/components/Link';

import TaskCalendarButton from 'web/components/TaskCalendarButton';
import TaskDeadlineEntryStatusInPeriod from 'web/components/companiesArchive/TaskDeadlineEntryStatusInPeriod';
import TaskDeadlineEntryStatusByType from 'web/components/companiesArchive/TaskDeadlineEntryStatusByType';
import QualityControlControlStatus from 'web/components/companiesArchive/QualityControlControlStatus';
import AnnualSettlementControlStatus from 'web/components/companiesArchive/AnnualSettlementControlStatus';

import RiskScore from 'web/components/companiesArchive/RiskScore';

import { PAGINATE_TENANTS } from 'api/queries/tenantQueries';

const RowWrapper = styled.div`
	position: relative;
	grid-column: 1 / -1;
`;

const MenuButton = styled(Button)`
	position: absolute;
	right: 0;
	top: 0;
	height: 72px;
	min-width: 40px;

	&:hover {
		.fa-ellipsis-v {
			color: black;
		}
	}
`;

export default function CompaniesArchive() {
	const { set } = useWebApp();
	const { userHasMinRole } = useUser();
	const [{ orderBy, order }, setNewOrder] = useSortOrder('name');

	const { search, task, accountant, accountantRole, company, accountType } =
		useAppFilters();

	const [menuAnchor, setMenuAnchor] = useState(null);
	const [activeMenuItem, setActiveMenuItem] = useState(null);

	const [annualFinancialStatus, setAnnualFinancialStatus] = useState(null);

	const {
		data: { companies = { count: 0, items: [] } } = {
			companies: { count: 0, items: [] },
		},
		loading,
		error,
		fetchMore,
		refetch,
	} = useQuery(PAGINATE_TENANTS, {
		variables: {
			search,
			// We need to convert the empty string we get from MUI Autocomplete to a null value.
			filter: {
				accountantId: accountant?.value || null,
				accountantRole: accountantRole?.value || null,
				company: company?.value || null,
				accountType: accountType?.value || null,
			},
			orderBy,
			order,
			offset: 0,
			limit: 50,
		},
		fetchPolicy: 'cache-and-network',
		errorPolicy: 'all',
		notifyOnNetworkStatusChange: true,
	});

	function menuOpen(event, item) {
		setMenuAnchor(event.currentTarget);
		setActiveMenuItem(item);
	}

	function menuClose() {
		setMenuAnchor(null);
	}

	return (
		<DesktopLayout
			breadcrumbs={[
				{
					label: 'Bedrifter',
				},
			]}
			title="Bedrifter"
		>
			<Content>
				<ErrorMessage errors={error} />

				<>
					<ArchiveHeader>
						<ArchiveHeaderRow>
							<SearchBar label={'Søk etter bedrift'} />

							<AppFilters
								displayFilters={[
									'task',
									'month',
									'year',
									'company',
									'adminsAndAccountants',
									'accountantRole',
									'accountType',
								]}
								size="medium"
							/>
						</ArchiveHeaderRow>

						<ArchiveHeaderRow>
							<AppActiveFilters
								displayFilters={[
									'year',
									'month',
									'task',
									'adminsAndAccountants',
									'accountantRole',
									'company',
									'accountType',
								]}
							/>
						</ArchiveHeaderRow>
					</ArchiveHeader>

					<Table columns="10">
						<TableRow header>
							<TableCell
								start="1"
								end="4"
								onClick={() => setNewOrder('name')}
							>
								<span>Navn</span>

								<SortIcon
									field="name"
									orderBy={orderBy}
									order={order}
								/>
							</TableCell>

							<TableCell center>
								{task?.value ?? 'Velg en oppgave'}
							</TableCell>

							<TableCell center>Skattemelding</TableCell>

							<TableCell center>Årsregnskap</TableCell>

							<TableCell center>Kvalitetskontroll</TableCell>

							<TableCell center>Årsoppgjørkontroll</TableCell>

							<TableCell center>Risiko</TableCell>

							<TableCell center>Avvik</TableCell>
						</TableRow>

						{!loading && companies?.items?.length === 0 && (
							<TableRow transparent>
								<TableCell start="1" end="-1" center>
									Ingen bedrifter ble funnet
								</TableCell>
							</TableRow>
						)}

						{!error &&
							companies?.items?.map(c => (
								<RowWrapper key={c._id} className="row">
									<TableRow hover>
										<TableCell start="1" end="4">
											<TaskCalendarButton
												companyId={c._id}
											/>

											<StyledLink
												to={`/bedrifter/${c._id}/`}
												onClick={() =>
													set({ tenant: c })
												}
											>
												{c.name}
											</StyledLink>
										</TableCell>

										<TableCell center>
											<TaskDeadlineEntryStatusInPeriod
												companyId={c._id}
											/>
										</TableCell>

										<TableCell center>
											<TaskDeadlineEntryStatusByType
												companyId={c._id}
												type="TAX_NOTICE"
											/>
										</TableCell>

										<TableCell center>
											<TaskDeadlineEntryStatusByType
												companyId={c._id}
												type="ANNUAL_ACCOUNTS"
											/>
										</TableCell>

										<QualityControlControlStatus
											companyId={c._id}
										/>

										<AnnualSettlementControlStatus
											companyId={c._id}
											selfRevisionRequired={
												c.selfRevisionRequired
											}
											reviewedAnnualFinancialStatus={
												c.reviewedAnnualFinancialStatus
											}
										/>

										<TableCell center>
											{c.accountTypes.includes(
												'accounting'
											) && (
												<RiskScore companyId={c._id} />
											)}
										</TableCell>

										<TableCell
											to={`/bedrifter/${c._id}/rediger/`}
											center
										>
											<StatusIcon
												error={
													c.dataDeviations
														.hasDeviations
												}
												errorTitle="Bedriften har avvik!"
												successTitle="Bedriften har ingen avvik!"
											/>
										</TableCell>
									</TableRow>

									<MenuButton
										size="small"
										onClick={e => {
											menuOpen(e, c);
										}}
										role="button"
									>
										<MoreVertIcon />
									</MenuButton>
								</RowWrapper>
							))}

						{loading && (
							<TableRow transparent>
								<TableCell start="1" end="-1">
									<InlineLoading />
								</TableCell>
							</TableRow>
						)}

						{!error &&
							!loading &&
							companies?.items?.length !== 0 && (
								<TableRow footer>
									<TableCell start="1" end="-1" right>
										Viser {companies.items.length} av{' '}
										{companies.count} bedrifter
									</TableCell>
								</TableRow>
							)}
					</Table>

					<Menu
						anchorEl={menuAnchor}
						open={Boolean(menuAnchor)}
						onClose={menuClose}
						anchorOrigin={{
							horizontal: 'right',
							vertical: 'bottom',
						}}
					>
						<MenuItem
							onClick={() => {
								setAnnualFinancialStatus({
									showModal: true,
									companyId: activeMenuItem._id,
									items: activeMenuItem.annualFinancialStatus,
								});
								menuClose();
							}}
						>
							Sett klar til kontroll
						</MenuItem>
					</Menu>

					{companies && companies.count > companies.items.length && (
						<LoadMoreButton
							disabled={loading}
							onClick={() => {
								fetchMore({
									variables: {
										offset: companies.items.length,
									},
									updateQuery: (
										prev,
										{ fetchMoreResult }
									) => {
										if (
											fetchMoreResult.companies.items
												.length === 0
										) {
											return prev;
										}

										const result = {
											companies: {
												...prev.companies,
												items: [
													...prev.companies.items,
													...fetchMoreResult.companies
														.items,
												],
											},
										};

										return result;
									},
								});
							}}
						/>
					)}
				</>
			</Content>

			{annualFinancialStatus?.showModal && (
				<CompanyAnnualFinancialStatusModal
					annualFinancialStatus={annualFinancialStatus.items}
					companyId={annualFinancialStatus.companyId}
					onClose={() => {
						setAnnualFinancialStatus(null);
						setActiveMenuItem(null);
						refetch();
					}}
				/>
			)}

			{userHasMinRole('admin') && <CreateFabButton to="ny/" />}
		</DesktopLayout>
	);
}

import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import { useWatch, useFormContext } from 'react-hook-form';
import Button from '@mui/material/Button';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import ErrorMessage from 'components/ErrorMessage';
import { InlineLoading } from 'components/Loading';

import DesktopLayout, {
	WideContent,
	StickyAction,
} from 'web/components/Layout';

import Form, { FormGroup, DatePicker, Select, Switch } from 'web/form/Form';

import { GET_ONE_COMPANY } from 'api/queries/tenantQueries';

import { CREATE_DEFAULT_COMPANY_ASSIGNMENT_TASKS } from 'api/mutations/companyAssignmentTaskMutations';

// TODO: Don't duplicate. Also in CompanyAssignmentTaskCreate and on the server
const frequencies = [
	{ value: 'monthly', label: 'Månedlig' },
	{ value: 'biMonthly', label: '2-månedlig' },
	{ value: 'quarterly', label: 'Kvartalsvis' },
	{ value: 'tertiary', label: 'Tertialvis' },
	{ value: 'halfYearly', label: 'Halvårlig' },
	{ value: 'yearly', label: 'Årlig' },
];

const weekdays = [
	{ value: 1, label: 'Mandag' },
	{ value: 2, label: 'Tirsdag' },
	{ value: 3, label: 'Onsdag' },
	{ value: 4, label: 'Torsdag' },
	{ value: 5, label: 'Fredag' },
];
const monthDays = Array.from({ length: 31 }, (_, i) => ({
	value: i + 1,
	label: i + 1,
}));

const months = [
	{ value: 0, label: 'Januar' },
	{ value: 1, label: 'Februar' },
	{ value: 2, label: 'Mars' },
	{ value: 3, label: 'April' },
	{ value: 4, label: 'Mai' },
	{ value: 5, label: 'Juni' },
	{ value: 6, label: 'Juli' },
	{ value: 7, label: 'August' },
	{ value: 8, label: 'September' },
	{ value: 9, label: 'Oktober' },
	{ value: 10, label: 'November' },
	{ value: 11, label: 'Desember' },
];

const relativeMonths = [
	{ value: 0, label: 'Samme mnd.' },
	{ value: 1, label: 'Neste mnd.' },
	{ value: 2, label: 'Om 2 mnd.' },
	{ value: 3, label: 'Om 3 mnd.' },
	{ value: 4, label: 'Om 4 mnd.' },
	{ value: 5, label: 'Om 5 mnd.' },
	{ value: 6, label: 'Om 6 mnd.' },
	{ value: 7, label: 'Om 7 mnd.' },
	{ value: 8, label: 'Om 8 mnd.' },
	{ value: 9, label: 'Om 9 mnd.' },
	{ value: 10, label: 'Om 10 mnd.' },
	{ value: 11, label: 'Om 11 mnd.' },
	{ value: 12, label: 'Om 12 mnd.' },
];

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 30px;
`;

const Column = styled.div``;

export default function CompanyAssignmentTasksCreate() {
	const { notify } = useSnackbar();
	const navigate = useNavigate();
	const { companyId } = useParams();

	const { data: { company } = { company: null }, error: companyError } =
		useQuery(GET_ONE_COMPANY, {
			fetchPolicy: 'cache-and-network',
			errorPolicy: 'all',
			variables: {
				_id: companyId,
			},
		});

	const [
		createDefaultTasks,
		{ loading: createDefaultTasksLoading, error: createDefaultTasksError },
	] = useMutation(CREATE_DEFAULT_COMPANY_ASSIGNMENT_TASKS);

	return (
		<DesktopLayout
			title="Opprett oppdrag"
			breadcrumbs={[
				{
					to: '/bedrifter/',
					label: 'Bedrifter',
				},
				{
					to: `/bedrifter/${company?._id}/rediger/`,
					label: company?.name || '...',
				},
				{
					to: `/bedrifter/${company?._id}/rediger/?tab=5`,
					label: 'Oppdrag',
				},
				{
					label: 'Opprett oppdrag',
				},
			]}
		>
			<WideContent>
				<ErrorMessage
					errors={[companyError, createDefaultTasksError]}
				/>

				{createDefaultTasksLoading ? (
					<InlineLoading text="Oppretter standard oppgaver…" />
				) : (
					company && (
						<>
							<h1>Planlegg oppdrag</h1>

							<Form
								values={{
									frequency: 'biMonthly',
									salaryFrequency: 'monthly',
								}}
								onSubmit={async values => {
									try {
										if (!values.includeSalary) {
											delete values.salaryFrequency;
											delete values.salaryDeadline;
										}

										//TODO: Cleanup the deadline property before save?
										// The form doesn't remove the deadline property if the frequency is changed
										// Should this be done on the client or on the server?
										const { data } =
											await createDefaultTasks({
												variables: {
													companyId,
													...values,
												},
											});

										if (data?.tasksCreated) {
											notify('Oppgaver opprettet');

											navigate(
												`/bedrifter/${company._id}/oppdrag/planlegg/`
											);
										}
									} catch (err) {
										//TODO: Handle this better with a new Assignment collection?
										if (
											err?.graphQLErrors[0]?.extensions
												?.code === 'DATA_EXISTS'
										) {
											navigate(
												`/bedrifter/${company._id}/oppdrag/planlegg/`
											);
										}

										console.error(err);
									}
								}}
							>
								<Grid>
									<Column>
										<FormGroup>
											<DatePicker
												name="startDate"
												label="Startdato"
											/>
										</FormGroup>

										<FormGroup>
											<p>
												<i>
													Velg hvilken frekvens alle
													oppgaver skal opprettes med
													som standard.
													<br />
													Det er mulig å endre dette
													individuelt for hver oppgave
													i neste steg.
												</i>
											</p>
										</FormGroup>

										<FrequencyFields
											frequencyFieldName="frequency"
											deadlineFieldName="deadline"
										/>
									</Column>

									<Column>
										<SalaryFields />
									</Column>
								</Grid>

								<StickyAction>
									<Button
										type="submit"
										variant="contained"
										size="large"
										disabled={createDefaultTasksLoading}
									>
										{createDefaultTasksLoading
											? 'Oppretter…'
											: 'Planlegg'}
									</Button>
								</StickyAction>
							</Form>
						</>
					)
				)}
			</WideContent>
		</DesktopLayout>
	);
}

// TODO: Duplicate. Also in CompanyAssignmentTasksEdit
function FrequencyFields({ frequencyFieldName, deadlineFieldName }) {
	const { control } = useFormContext();

	const frequencyValue = useWatch({
		name: frequencyFieldName,
		control,
	});

	return (
		<div>
			<FormGroup>
				<Select
					name={frequencyFieldName}
					label="Frekvens"
					options={frequencies}
					fullWidth
					required
				/>
			</FormGroup>

			<FrequencyDeadlineFields
				frequency={frequencyValue}
				deadlineFieldName={deadlineFieldName}
			/>
		</div>
	);
}

// TODO: Duplicate. Also in CompanyAssignmentTasksEdit
function FrequencyDeadlineFields({ frequency, deadlineFieldName }) {
	if (frequency === 'weekly') {
		return (
			<FormGroup>
				<Select
					name={`${deadlineFieldName}.weekday`}
					label="Ukedag"
					defaultValue={5}
					options={weekdays}
					fullWidth
					required
				/>
			</FormGroup>
		);
	} else if (frequency === 'monthly') {
		return (
			<FormGroup>
				<Select
					name={`${deadlineFieldName}.day`}
					label="Dag i måneden"
					defaultValue={20}
					options={monthDays}
					fullWidth
					required
				/>
			</FormGroup>
		);
	} else if (
		frequency === 'biMonthly' ||
		frequency === 'quarterly' ||
		frequency === 'tertiary' ||
		frequency === 'halfYearly'
	) {
		return (
			<>
				<FormGroup>
					<Select
						name={`${deadlineFieldName}.day`}
						label="Dag i måneden"
						defaultValue={20}
						options={monthDays}
						fullWidth
						required
					/>
				</FormGroup>

				<FormGroup>
					<Select
						name={`${deadlineFieldName}.relativeMonth`}
						label="Relativ måned"
						defaultValue={1}
						options={relativeMonths}
						fullWidth
						required
					/>
				</FormGroup>
			</>
		);
	} else if (frequency === 'yearly') {
		return (
			<>
				<FormGroup>
					<Select
						name={`${deadlineFieldName}.day`}
						label="Dag i måneden"
						defaultValue={20}
						options={monthDays}
						fullWidth
						required
					/>
				</FormGroup>

				<FormGroup>
					<Select
						name={`${deadlineFieldName}.month`}
						label="Måned"
						defaultValue={0}
						options={months}
						fullWidth
						required
					/>
				</FormGroup>
			</>
		);
	} else if (frequency === 'custom') {
		return <div>Her må vi ha et eller annet</div>;
	}

	return null;
}

function SalaryFields() {
	const { control } = useFormContext();

	const includeSalaryValue = useWatch({
		name: 'includeSalary',
		control,
		defaultValue: true,
	});

	return (
		<>
			<FormGroup>
				<Switch
					name="includeSalary"
					label="Skal det utføres lønnskjøring på bedriften?"
					defaultChecked={true}
				/>
			</FormGroup>

			{includeSalaryValue && (
				<FrequencyFields
					frequencyFieldName="salaryFrequency"
					deadlineFieldName="salaryDeadline"
				/>
			)}
		</>
	);
}

import Filter from 'components/filters/Filter';

const allOption = { value: '', label: 'Alle typer' };
const accontantRolesOptions = [
	{ value: 'accountant', label: 'Regnskapsfører' },
	{ value: 'accountmanager', label: 'Kundeansvarlig' },
	{ value: 'controller', label: 'Oppdragskontrollør' },
];

export default function AccountantRoleFilterLegacy({
	accountantsFilter,
	setAccountantsFilter,
}) {
	return (
		<Filter
			name="accountants-filter"
			label="Vis rolle"
			value={accountantsFilter || allOption}
			options={[allOption, ...accontantRolesOptions]}
			onChange={setAccountantsFilter}
		/>
	);
}

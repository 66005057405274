import { useAppFilters } from 'web/contexts/AppFiltersContext';

import Filter from 'components/filters/Filter';

const options = [
	{
		value: '',
		label: 'Alle kontotyper',
	},
	{
		value: 'accounting',
		label: 'Regnskapskunde',
	},
	{
		value: 'counseling',
		label: 'Rådgivningskunde',
	},
	{
		value: 'lead',
		label: 'Potensiell kunde',
	},
];

export default function AccountTypeFilter({ size = 'medium' }) {
	const { accountType, setAccountType } = useAppFilters();
	return (
		<Filter
			name="accounttype-filter"
			label="Vis kontotype"
			value={accountType || options[0]}
			options={options}
			onChange={setAccountType}
			size={size}
		/>
	);
}

import { gql } from '@apollo/client';

export const PAGINATE_HELP_PAGES = gql`
	query paginateHelpPages(
		$order: Int
		$orderBy: String
		$limit: Int
		$offset: Int
		$search: String
	) {
		helpPages: paginateHelpPages(
			order: $order
			orderBy: $orderBy
			limit: $limit
			skip: $offset
			search: $search
		) {
			count
			items {
				_id
				name
			}
		}
	}
`;

export const GET_HELP_PAGES = gql`
	query getHelpPages {
		helpPages: getHelpPages {
			_id
			name
		}
	}
`;

export const FIND_HELP_PAGE = gql`
	query findOneHelpPage($_id: ID!) {
		helpPage: findOneHelpPage(_id: $_id) {
			_id
			name
		}
	}
`;

import { useState } from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { useConfirmDialog } from 'components/contexts/ConfirmContext';
import { useSnackbar } from 'web/contexts/SnackbarContext';

import ErrorMessage from 'components/ErrorMessage';

import { UnderlineLink } from 'web/components/Link';

import { SEND_COMPANY_CONTRACT_SIGNING_REQUEST_NOTIFICATION } from 'api/mutations/companyContractMutations';

const Contract = styled.div`
	margin-bottom: 20px;
`;

const Signees = styled.div`
	background-color: ${({ theme }) => theme.palette.background.paper};
	margin: 20px 0 0 0;
	padding: 20px;
`;

export default function CompanyContractsDialog({ contracts, open, onClose }) {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth={true}
			maxWidth="sm"
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				Oppdragsavtaler som venter på signering
			</DialogTitle>

			<DialogContent>
				{contracts?.length > 0 ? (
					<div>
						{contracts.map(contract => (
							<Contract key={contract._id}>
								<div>
									<UnderlineLink
										to={`/bedrifter/${contract.company._id}/rediger/?tab=3`}
									>
										{contract.company.name}
									</UnderlineLink>
								</div>

								<Signees>
									{contract.signingUsers.map(user => (
										<Signee
											key={user._id}
											signee={user}
											contractId={contract._id}
										/>
									))}
								</Signees>
							</Contract>
						))}
					</div>
				) : (
					<div>Ingen kontrakter mangler signering!</div>
				)}
			</DialogContent>

			<DialogActions>
				<Button onClick={onClose} variant="cotained">
					Lukk
				</Button>
			</DialogActions>
		</Dialog>
	);
}

const SigneeWrapper = styled.div`
	margin-bottom: 10px;

	&:last-of-type {
		margin-bottom: 0;
	}
`;

const SigneeContent = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

const SigneeName = styled.div`
	flex: 1;
`;

const SignatureStatus = styled.div`
	background-color: ${({ $signed, theme }) =>
		$signed
			? theme.palette.status.light.green
			: theme.palette.status.light.yellow};
	border-radius: 50px;
	font-size: 14px;
	min-width: 70px;
	padding: 5px 10px;
	text-align: center;
`;

function Signee({ signee, contractId }) {
	const { verify } = useConfirmDialog();
	const { notify } = useSnackbar();

	const [signatureRequestSent, setSignatureRequestSent] = useState(false);

	const [resendSigningRequest, { loading, error }] = useMutation(
		SEND_COMPANY_CONTRACT_SIGNING_REQUEST_NOTIFICATION
	);

	return (
		<SigneeWrapper>
			<ErrorMessage errors={[error]} />

			<SigneeContent>
				<SigneeName>{signee.name}</SigneeName>

				<Tooltip title="Send påminnelse">
					<div>
						<IconButton
							disabled={
								loading || signee.signed || signatureRequestSent
							}
							onClick={async () => {
								try {
									if (
										!(await verify({
											title: 'Nytt varsel',
											text: `Er du sikker på at du ønsker å sende ut et nytt varsel til ${signee.name}?`,
										}))
									) {
										return;
									}

									const { data } = await resendSigningRequest(
										{
											variables: {
												_id: contractId,
												signingUserId: signee._id,
											},
										}
									);

									if (data) {
										setSignatureRequestSent(true);

										notify('Nytt varsel ble sendt!');
									}
								} catch (err) {
									console.error(err);
								}
							}}
							aria-label="Send påminnelse"
						>
							<NotificationsIcon fontSize="inherit" />
						</IconButton>
					</div>
				</Tooltip>

				<SignatureStatus $signed={signee.signed}>
					{signee.signed ? 'Signert' : 'Venter'}
				</SignatureStatus>
			</SigneeContent>
		</SigneeWrapper>
	);
}

import { useAppFilters } from 'web/contexts/AppFiltersContext';

import Filter from 'components/filters/Filter';

const currentYear = new Date().getFullYear();

const yearOptions = [
	currentYear - 2,
	currentYear - 1,
	currentYear,
	currentYear + 1,
];

export default function YearFilter({ size = 'medium' }) {
	const { year, setYear } = useAppFilters();

	return (
		<Filter
			name="year-filter"
			label="Vis år"
			value={year}
			options={yearOptions.map(year => ({
				value: year,
				label: year.toString(),
			}))}
			onChange={setYear}
			size={size}
			minWidth="100px"
		/>
	);
}

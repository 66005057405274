import { useQuery } from '@apollo/client';

import { useAppFilters } from 'web/contexts/AppFiltersContext';
import { useStatisticsDialog } from 'web/contexts/StatisticsDialogContext';

import ErrorAlert from 'components/ErrorAlert';

import { WidgetListItem } from 'web/components/widgets/components/WidgetList';

export default function WidgetListData({
	label,
	query,
	link,
	name = null,
	variables = {},
	total = null,
	hideIfEmpty = false,
	onLoaded = null,
	DialogContent = null,
}) {
	const { showDialog } = useStatisticsDialog();
	const {
		year,
		month,
		task,
		department,
		accountant,
		accountantRole,
		company,
	} = useAppFilters();

	const {
		data: { items } = {
			items: [],
		},
		loading,
		error,
	} = useQuery(query, {
		variables: {
			...variables,
			filter: {
				year: year?.value,
				month: month?.value,
				task: task?.value,
				departmentId: department?.value,
				accountantId: accountant?.value,
				accountantRole: accountantRole?.value,
				company: company?.value,
			},
		},
		onCompleted: ({ items }) => {
			if (!name || !onLoaded) return;

			onLoaded(name, items.length);
		},
	});

	if (hideIfEmpty && loading) {
		return null;
	}

	if (hideIfEmpty && (!items || items.length === 0)) {
		return null;
	}

	return (
		<>
			<ErrorAlert error={error} />

			<WidgetListItem
				label={label}
				value={items?.length ?? 0}
				loading={loading}
				onClick={() =>
					showDialog(
						label,
						items,
						link,
						DialogContent ? (
							<DialogContent items={items} link={link} />
						) : null
					)
				}
				total={total}
			/>
		</>
	);
}

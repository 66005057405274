import React, { useState, Fragment } from 'react';
import { createPortal } from 'react-dom';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';

import { formatDate } from 'utils/dates';

import CompanyContractCreateModal from 'web/screens/modals/CompanyContractCreateModal';

import ErrorMessage from 'components/ErrorMessage';
import { StyledListWithA } from 'components/MaterialList';
import { CompanyContractsStatusPill } from 'components/Pill';

import Panel from 'web/components/panels/Panel';

import { PAGINATE_COMPANY_CONTRACTS } from 'api/queries/companyContractQueries';

const AddButtonWrapper = styled.div`
	text-align: right;
`;

const Text = styled.div`
	color: ${p => p.theme.palette.status.subdued};
	font-size: 0.9rem;
	margin-top: 5px;
`;

export const EmptyList = styled.div`
	background: white;
	padding: 16px;
	margin-bottom: 20px;
`;

const TextWrapper = styled.div`
	font-size: 14px;
	text-align: center;
`;

export default function CompanyContractsPanel({ companyId }) {
	const [showCreateContractModal, setShowCreateContractModal] =
		useState(false);

	const {
		data: { contracts = { count: 0, items: [] } } = {
			contracts: { count: 0, items: [] },
		},
		error,
	} = useQuery(PAGINATE_COMPANY_CONTRACTS, {
		variables: {
			companyId,
			orderBy: 'createdAt',
			order: -1,
		},
	});

	return (
		<Fragment>
			<Panel
				title="Oppdragsavtaler"
				action={
					<AddButtonWrapper>
						<Button
							endIcon={<AddIcon size="small" />}
							size="medium"
							variant="contained"
							color="primary"
							disabled={showCreateContractModal}
							onClick={() => {
								setShowCreateContractModal(true);
							}}
						>
							Opprett ny
						</Button>
					</AddButtonWrapper>
				}
			>
				<ErrorMessage errors={[error]} />

				{contracts?.items?.length > 0 ? (
					<StyledListWithA>
						{contracts.items.map(
							({
								_id,
								typeLabel,
								isSentToSigning,
								isFullySigned,
								createdAt,
								createdByDisplayName,
								updatedAt,
								updatedByDisplayName,
							}) => {
								const createdDate = formatDate(
									createdAt,
									'DD.MM.YY'
								);

								return (
									<Fragment key={_id}>
										<ListItem
											secondaryAction={
												<TextWrapper>
													<div>{createdDate}</div>

													<ContractStatus
														isSentToSigning={
															isSentToSigning
														}
														isFullySigned={
															isFullySigned
														}
													/>
												</TextWrapper>
											}
										>
											<ListItemText>
												<Link
													to={`/bedrifter/${companyId}/avtaler/${_id}/rediger/`}
												>
													{typeLabel}
												</Link>

												<Text>
													Sist oppdatert{' '}
													{formatDate(
														updatedAt || createdAt,
														'DD.MM.YY [kl] HH:mm'
													)}{' '}
													av{' '}
													{updatedByDisplayName ||
														createdByDisplayName}
												</Text>
											</ListItemText>
										</ListItem>
										<Divider component="li" />
									</Fragment>
								);
							}
						)}
					</StyledListWithA>
				) : (
					<EmptyList>Bedriften har ingen oppdragsavtaler.</EmptyList>
				)}
			</Panel>

			{showCreateContractModal &&
				createPortal(
					<CompanyContractCreateModal
						companyId={companyId}
						onClose={() => setShowCreateContractModal(false)}
					/>,
					document.getElementById('modal-root')
				)}
		</Fragment>
	);
}

export function ContractStatus({ isSentToSigning, isFullySigned }) {
	let statusText = 'Opprettet';
	let statusCode = 'CREATED';

	if (isSentToSigning && isFullySigned) {
		statusText = 'Signert';
		statusCode = 'SIGNED';
	} else if (isSentToSigning && !isFullySigned) {
		statusText = 'Til signering';
		statusCode = 'SIGNING';
	}

	return (
		<CompanyContractsStatusPill $statusCode={statusCode}>
			{statusText}
		</CompanyContractsStatusPill>
	);
}

import { gql } from '@apollo/client';

export const INSERT_ONE_COMPANY_CONTROL = gql`
	mutation insertOneCompanyControl(
		$companyId: ID!
		$type: String!
		$year: Int!
	) {
		control: insertOneCompanyControl(
			companyId: $companyId
			type: $type
			year: $year
		) {
			_id
			companyId
			createdAt
			createdBy
			createdByDisplayName
			name
			year
			updatedAt
			updatedBy
			updatedByDisplayName
			type {
				value
				label
			}
		}
	}
`;

export const DELETE_ONE_COMPANY_CONTROL = gql`
	mutation deleteOneCompanyControl($_id: ID!) {
		deleted: deleteOneCompanyControl(_id: $_id)
	}
`;

export const INSERT_ONE_COMPANY_CONTROL_QUESTION = gql`
	mutation insertOneCompanyControlQuestion(
		$controlId: ID!
		$companyId: ID!
		$question: String!
	) {
		question: insertOneCompanyControlQuestion(
			controlId: $controlId
			companyId: $companyId
			question: $question
		) {
			_id
			label
		}
	}
`;

export const SET_COMPANY_CONTROL_STATUS = gql`
	mutation setCompanyControlStatus(
		$_id: ID!
		$newStatusCode: CompanyControlStatusCodes!
	) {
		updated: setCompanyControlStatus(
			_id: $_id
			newStatusCode: $newStatusCode
		)
	}
`;

export const BULK_SET_COMPANY_CONTROL_QUESTION_VALUES = gql`
	mutation bulkSetCompanyControlQuestionValues(
		$controlId: ID!
		$categoryName: String!
		$value: String!
	) {
		updated: bulkSetCompanyControlQuestionValues(
			controlId: $controlId
			categoryName: $categoryName
			value: $value
		)
	}
`;

export const SET_COMPANY_CONTROL_QUESTION_VALUE = gql`
	mutation setCompanyControlQuestionValue($questionId: ID!, $value: String!) {
		updated: setCompanyControlQuestionValue(
			questionId: $questionId
			value: $value
		)
	}
`;

export const ADD_COMPANY_CONTROL_QUESTION_COMMENT = gql`
	mutation addCompanyControlQuestionComment(
		$questionId: ID!
		$message: String!
	) {
		updated: addCompanyControlQuestionComment(
			questionId: $questionId
			message: $message
		)
	}
`;

export const DELETE_COMPANY_CONTROL_QUESTION_COMMENT = gql`
	mutation deleteCompanyControlQuestionComment(
		$questionId: ID!
		$commentId: ID!
	) {
		deleted: deleteCompanyControlQuestionComment(
			questionId: $questionId
			commentId: $commentId
		)
	}
`;

export const ADD_COMPANY_CONTROL_QUESTION_DEVIATION = gql`
	mutation addCompanyControlQuestionDeviation(
		$questionId: ID!
		$description: String!
	) {
		updated: addCompanyControlQuestionDeviation(
			questionId: $questionId
			description: $description
		)
	}
`;

export const ADD_COMPANY_CONTROL_QUESTION_DEVIATION_COMMENT = gql`
	mutation addCompanyControlDeviationComment(
		$questionId: ID!
		$deviationId: ID!
		$message: String!
	) {
		updated: addCompanyControlDeviationComment(
			questionId: $questionId
			deviationId: $deviationId
			message: $message
		)
	}
`;

export const SET_COMPANY_CONTROL_QUESTION_DEVIATION_STATUS = gql`
	mutation setCompanyControlQuestionDeviationStatus(
		$questionId: ID!
		$deviationId: ID!
		$completed: Boolean
		$approved: Boolean
	) {
		updated: setCompanyControlQuestionDeviationStatus(
			questionId: $questionId
			deviationId: $deviationId
			completed: $completed
			approved: $approved
		)
	}
`;

export const DELETE_COMPANY_CONTROL_QUESTION_DEVIATION = gql`
	mutation deleteCompanyControlQuestionDeviation(
		$questionId: ID!
		$deviationId: ID!
	) {
		deleted: deleteCompanyControlQuestionDeviation(
			questionId: $questionId
			deviationId: $deviationId
		)
	}
`;

import styled from 'styled-components';
import debounce from 'lodash/debounce';
import MUITextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { useEffect, useRef } from 'react';

import Icon from 'components/Icon';

const SearchBarWrapper = styled.div`
	margin: 0;
	flex: 1 1 0%;
`;

const SearchBar = ({ label, value = '', updateSearch }) => {
	const handleChange = debounce(value => updateSearch(value), 750);
	const inputRef = useRef();

	useEffect(() => {
		// Listen for changes in other components and update the value
		inputRef.current.value = value;
	}, [value]);

	return (
		<SearchBarWrapper>
			<MUITextField
				label={label}
				inputRef={inputRef}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<Icon icon="search" />
						</InputAdornment>
					),
				}}
				variant={'outlined'}
				fullWidth={true}
				onChange={({ target }) => handleChange(target.value)}
			/>
		</SearchBarWrapper>
	);
};

export default SearchBar;

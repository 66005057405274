import { gql } from '@apollo/client';

export const INSERT_COMPANY = gql`
	mutation insertOneTenant(
		$name: String!
		$accountTypes: [AccountType!]!
		$clientNo: String
		$orgNo: String
		$freeText: String
	) {
		company: insertOneTenant(
			name: $name
			accountTypes: $accountTypes
			clientNo: $clientNo
			orgNo: $orgNo
			freeText: $freeText
		) {
			_id
		}
	}
`;

export const UPDATE_COMPANY = gql`
	mutation updateOneTenant(
		$_id: ID!
		$name: String!
		$accountTypes: [AccountType!]!
		$clientNo: String
		$orgNo: String
		$phone: String
		$email: String
		$accountantIds: [ID]
		$accountManagerIds: [ID]
		$payrollManagerIds: [ID]
		$annualAccountsManagerIds: [ID]
		$advisorIds: [ID]
		$controllerIds: [ID]
		$auditorIds: [ID]
		$categoryIds: [ID]
		$departmentId: ID
		$freeText: String
		$isInactive: Boolean
		$userRoles: [UserRoleInput]
	) {
		company: updateOneTenant(
			_id: $_id
			name: $name
			accountTypes: $accountTypes
			clientNo: $clientNo
			orgNo: $orgNo
			phone: $phone
			email: $email
			accountantIds: $accountantIds
			accountManagerIds: $accountManagerIds
			payrollManagerIds: $payrollManagerIds
			annualAccountsManagerIds: $annualAccountsManagerIds
			advisorIds: $advisorIds
			controllerIds: $controllerIds
			auditorIds: $auditorIds
			departmentId: $departmentId
			categoryIds: $categoryIds
			freeText: $freeText
			isInactive: $isInactive
			userRoles: $userRoles
		) {
			_id
		}
	}
`;

export const ARCHIVE_COMPANY = gql`
	mutation archiveOneTenant($_id: ID!) {
		deleted: archiveOneTenant(_id: $_id)
	}
`;

export const ADD_OWNER_TO_COMPANY = gql`
	mutation addOwnerToTenant($_id: ID!, $userId: ID!, $note: String) {
		added: addOwnerToTenant(_id: $_id, userId: $userId, note: $note)
	}
`;

export const UPDATE_OWNER_ON_COMPANY = gql`
	mutation updateOwnerOnTenant($_id: ID!, $userId: ID!, $note: String) {
		updated: updateOwnerOnTenant(_id: $_id, userId: $userId, note: $note)
	}
`;

export const REMOVE_OWNER_FROM_COMPANY = gql`
	mutation removeOwnerFromTenant($_id: ID!, $userId: ID!) {
		removed: removeOwnerFromTenant(_id: $_id, userId: $userId)
	}
`;

export const UPDATE_COMPANY_PROFFDATA = gql`
	mutation updateProffData($_id: ID!) {
		updateProffData(_id: $_id) {
			_id
			proffData
		}
	}
`;

export const ADD_PROFF_DATA_DEVIATION_EXCEPTION = gql`
	mutation addProffDataDeviationException(
		$_id: ID!
		$exception: ProffDataDeviationExceptionInput!
	) {
		archived: addProffDataDeviationException(
			_id: $_id
			exception: $exception
		)
	}
`;

export const RESTORE_PROFF_DATA_DEVIATION_EXCEPTION = gql`
	mutation restoreProffDataDeviationException(
		$_id: ID!
		$exception: ProffDataDeviationExceptionInput!
	) {
		restored: restoreProffDataDeviationException(
			_id: $_id
			exception: $exception
		)
	}
`;

export const TOGGLE_FAVORITE = gql`
	mutation toggleFavorite($_id: ID!) {
		isFavorite: toggleFavorite(_id: $_id)
	}
`;

export const ADD_ANNUAL_FINANCIAL_STATUS = gql`
	mutation addAnnualFinancialSatus($_id: ID!, $year: Int!, $type: String) {
		added: addAnnualFinancialSatus(_id: $_id, year: $year, type: $type)
	}
`;

export const REMOVE_ANNUAL_FINANCIAL_STATUS = gql`
	mutation removeAnnualFinancialSatus($_id: ID!, $year: Int!) {
		removed: removeAnnualFinancialSatus(_id: $_id, year: $year)
	}
`;

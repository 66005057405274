import { useAppFilters } from 'web/contexts/AppFiltersContext';

import Filter from 'components/filters/Filter';

const monthOptions = [
	{ value: 0, label: 'Januar' },
	{ value: 1, label: 'Februar' },
	{ value: 2, label: 'Mars' },
	{ value: 3, label: 'April' },
	{ value: 4, label: 'Mai' },
	{ value: 5, label: 'Juni' },
	{ value: 6, label: 'Juli' },
	{ value: 7, label: 'August' },
	{ value: 8, label: 'September' },
	{ value: 9, label: 'Oktober' },
	{ value: 10, label: 'November' },
	{ value: 11, label: 'Desember' },
];

export default function MonthFilter({ size = 'medium' }) {
	const { month, setMonth } = useAppFilters();

	return (
		<Filter
			name="month-filter"
			label="Vis måned"
			value={month}
			options={monthOptions}
			onChange={setMonth}
			size={size}
			minWidth="140px"
		/>
	);
}

import { createContext, useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import Form from 'components/form/Form';
import TextField from 'components/form/TextField';

import { UPDATE_DOCUMENT_NODE } from 'api/mutations/documentMutations';

const RenameDocumentNodeModalContext = createContext({
	newName: () => {},
});

export function useRenameDialog() {
	const context = useContext(RenameDocumentNodeModalContext);

	if (context === undefined) {
		throw new Error(
			'useRenameDialog must be used within a RenameDocumentNodeModalProvider'
		);
	}

	return context;
}

export default function RenameDocumentNodeModalProvider({ children }) {
	const [isOpen, setIsOpen] = useState(false);
	const [documentNode, setDocumentNode] = useState(null);
	const [renameDocumentNode] = useMutation(UPDATE_DOCUMENT_NODE);

	const close = () => setIsOpen(false);
	const open = () => setIsOpen(true);

	return (
		<RenameDocumentNodeModalContext.Provider
			value={{
				rename: documentNode => {
					setDocumentNode(documentNode);
					open();
				},
			}}
		>
			{children}

			<Dialog
				title=""
				open={isOpen}
				fullWidth={true}
				maxWidth="sm"
				aria-labelledby="new-name-dialog-slide-title"
				aria-describedby="new-name-dialog-slide-description"
				onClose={close}
			>
				<DialogTitle id="new-name-dialog-slide-title">
					{`Gi «${documentNode && documentNode.name}» nytt navn?`}
				</DialogTitle>

				<Form
					values={documentNode}
					onSubmit={async newDocumentNode => {
						await renameDocumentNode({
							variables: {
								_id: documentNode._id,
								name: newDocumentNode.name,
							},
						});

						close();
					}}
				>
					<DialogContent>
						<TextField
							name="name"
							required={true}
							autoFocus
							onFocus={event => event.target.select()}
						/>
					</DialogContent>

					<DialogActions>
						<Button type="button" onClick={close}>
							Avbryt
						</Button>

						<Button type="submit" variant="contained">
							Bytt navn
						</Button>
					</DialogActions>
				</Form>
			</Dialog>
		</RenameDocumentNodeModalContext.Provider>
	);
}

import styled from 'styled-components';
import { formatDate } from 'utils/dates';

const Wrapper = styled.div`
	color: ${p =>
		p.$needsRevision
			? p.theme.palette.status.error
			: p.theme.palette.status.success};
	margin: 40px 0;
	font-style: italic;
`;

export default function Revision({ needsRevision = false, date, by }) {
	return (
		<Wrapper $needsRevision={needsRevision}>
			<strong>Sist revidert:</strong>{' '}
			<span>{formatDate(date, 'DD.MM.YY [kl] HH:mm')} av </span>{' '}
			<span>{by}</span>
		</Wrapper>
	);
}

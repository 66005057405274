import { useState, Fragment } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import {
	formatDate,
	getWeekNumber,
	getStartDateOfCurrentWeek,
	isToday,
	isDateSameOrBeforeToday,
} from 'utils/dates';

import { useAppFilters } from 'web/contexts/AppFiltersContext';

import EditAssignmentEntryDialog from 'web/screens/dialogs/assignmentTaskEntry/EditAssignmentEntryDialog';

import { InlineLoading } from 'components/Loading';
import ErrorAlert from 'components/ErrorAlert';

import Widget from 'web/components/widgets/Widget';

import TaskCalendarButton from 'web/components/TaskCalendarButton';
import { StyledLink } from 'web/components/Link';

import {
	MY_COMPANIES_WITH_DUE_TASK_DEADLINE_ENTRIES,
	TASK_DEADLINE_ENTRIES_ON_DATE,
} from 'api/queries/widgetQueries';

function getNext7Days(startDate) {
	let dates = [];

	for (let i = 0; i < 7; i++) {
		const newDate = new Date(startDate);
		newDate.setDate(startDate.getDate() + i);

		dates.push(newDate);
	}

	return dates;
}

const SelectedWeekButton = styled(Button)`
	color: ${p => p.theme.palette.text.primary};
	font-weight: bold;
	font-size: 14px;
	letter-spacing: 1px;
	min-width: auto;
	padding: 0;

	:disabled {
		color: ${p => p.theme.palette.text.primary};
	}
`;

export default function TaskWeekCalendarWidget() {
	const [startDate, setStartDate] = useState(getStartDateOfCurrentWeek());
	const weekDates = getNext7Days(startDate);

	return (
		<Widget
			title="Min uke"
			titleIcon="calendar-week"
			action={
				<WeekSelect startDate={startDate} setStartDate={setStartDate} />
			}
		>
			<WeekCalendar weekDays={weekDates} />
		</Widget>
	);
}

function WeekSelect({ startDate, setStartDate }) {
	const weekNumber = getWeekNumber(startDate);

	function goToCurrentWeek() {
		setStartDate(getStartDateOfCurrentWeek());
	}

	function previousWeek() {
		const newDate = new Date(startDate);
		newDate.setDate(newDate.getDate() - 7);

		setStartDate(newDate);
	}

	function nextWeek() {
		const newDate = new Date(startDate);
		newDate.setDate(newDate.getDate() + 7);

		setStartDate(newDate);
	}

	return (
		<div>
			<IconButton
				type="button"
				aria-label="Forrige uke"
				title="Forrige uke"
				size="small"
				onClick={previousWeek}
			>
				<ChevronLeftIcon fontSize="inherit" />
			</IconButton>

			<SelectedWeekButton
				title="Gå til gjeldende uke"
				size="small"
				onClick={goToCurrentWeek}
			>
				Uke {weekNumber}
			</SelectedWeekButton>

			<IconButton
				aria-label="Neste uke"
				title="Neste uke"
				size="small"
				onClick={nextWeek}
			>
				<ChevronRightIcon fontSize="inherit" />
			</IconButton>
		</div>
	);
}

const TimelineWrapper = styled.div`
	background-color: ${p => p.theme.palette.background.paper};
`;

const Timeline = styled.div`
	display: grid;
	grid-template-columns: 2fr repeat(7, 1fr);
	gap: 1px;
	padding: 1px;
`;

const Cell = styled.div`
	background-color: ${p =>
		p.$current
			? p.theme.palette.background.lightGrayBlue
			: p.theme.palette.background.default};
	min-height: 50px;
	display: flex;
	justify-content: ${p => (p.$center ? 'center' : 'flex-start')};
	align-items: center;
	padding: 5px 10px;
`;

const CellHeader = styled(Cell)`
	background-color: ${p =>
		p.$current
			? p.theme.palette.background.lightGrayBlue
			: p.theme.palette.background.gray};
	text-transform: capitalize;
`;

const NotFoundCell = styled(Cell)`
	grid-column: 1/-1;
`;

const Company = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

function WeekCalendar({ weekDays }) {
	const { department, accountant, accountantRole, task, company } =
		useAppFilters();

	const {
		data: { companies } = {
			companies: [],
		},
		loading,
		error,
	} = useQuery(MY_COMPANIES_WITH_DUE_TASK_DEADLINE_ENTRIES, {
		variables: {
			fromDate: weekDays[0],
			toDate: weekDays[weekDays.length - 1],
			filter: {
				departmentId: department?.value,
				accountantId: accountant?.value,
				accountantRole: accountantRole?.value,
				task: task?.value,
				company: company?.value,
			},
		},
	});

	if (loading) return <InlineLoading />;

	return (
		<TimelineWrapper>
			<ErrorAlert error={error} />

			<Timeline>
				<CellHeader></CellHeader>

				{weekDays.map(date => (
					<CellHeader
						key={date.toString()}
						$center={true}
						$current={isToday(date)}
					>
						{formatDate(date, 'dddd DD.MM')}
					</CellHeader>
				))}

				{companies.length > 0 ? (
					<Fragment>
						{companies.map(company => (
							<Fragment key={company._id}>
								<Cell key={company._id}>
									<Company>
										<TaskCalendarButton
											companyId={company._id}
										/>

										<StyledLink
											to={`/bedrifter/${company._id}/`}
										>
											{company.name}
										</StyledLink>
									</Company>
								</Cell>

								{weekDays.map(date => (
									<Cell
										key={date.toString()}
										$center={true}
										$current={isToday(date)}
									>
										<TaskDeadlineEntries
											companyId={company._id}
											date={date}
										/>
									</Cell>
								))}
							</Fragment>
						))}
					</Fragment>
				) : (
					<NotFoundCell $center={true}>
						Alle dine oppgaver er utført denne uken
					</NotFoundCell>
				)}
			</Timeline>
		</TimelineWrapper>
	);
}

function TaskDeadlineEntries({ companyId, date }) {
	const { task } = useAppFilters();

	const {
		data: { deadlineEntries } = {
			deadlineEntries: [],
		},
		loading,
		error,
	} = useQuery(TASK_DEADLINE_ENTRIES_ON_DATE, {
		variables: {
			companyId,
			date,
			filter: {
				task: task?.value,
			},
		},
	});

	if (error) {
		return <ErrorAlert error={error} />;
	}

	if (loading || deadlineEntries.length === 0) return null;

	return (
		<>
			<ErrorAlert error={error} />

			<TaskDeadlineEntriesCalendarIcon
				deadlineEntries={deadlineEntries}
				date={date}
				companyId={companyId}
			/>
		</>
	);
}

function TaskDeadlineEntriesCalendarIcon({ deadlineEntries, date, companyId }) {
	if (deadlineEntries.length === 1) {
		return (
			<TaskCalendarIconAction
				deadlineEntry={deadlineEntries[0]}
				isOverdue={isDateSameOrBeforeToday(date)}
			/>
		);
	}

	return (
		<TaskCalendarIconLink
			to={`/bedrifter/${companyId}/rediger/?tab=5`}
			count={deadlineEntries.length}
			isOverdue={isDateSameOrBeforeToday(date)}
		/>
	);
}

const TaskDeadlinesIconButton = styled(IconButton)`
	color: ${p =>
		p.$isOverdue
			? p.theme.palette.status.dark.red
			: p.theme.palette.status.dark.gray};
`;

function TaskCalendarIconAction({ deadlineEntry, isOverdue }) {
	const [showDialog, setShowDialog] = useState(false);

	return (
		<>
			<TaskDeadlinesIconButton
				title="Åpne oppgave"
				onClick={() => setShowDialog(true)}
				$isOverdue={isOverdue}
			>
				<CalendarMonthIcon />
			</TaskDeadlinesIconButton>

			<EditAssignmentEntryDialog
				deadlineEntry={deadlineEntry}
				open={showDialog}
				onClose={() => setShowDialog(false)}
			/>
		</>
	);
}

const TaskDeadlinesCountBadge = styled(Badge)`
	position: relative;

	.MuiBadge-badge {
		background-color: ${({ theme }) => theme.palette.background.paper};
		border: 2px solid ${({ theme }) => theme.palette.background.default};
		color: ${({ theme }) => theme.palette.text.primary};
		padding: 0;
		font-size: 12px;
		height: 20px;
		width: 25px;
		position: absolute;
		right: 3px;
		top: 13px;
	}
`;

function TaskCalendarIconLink({ count, to, isOverdue }) {
	return (
		<TaskDeadlinesCountBadge badgeContent={count} color="primary">
			<TaskDeadlinesIconButton
				title="Gå til oppdragsutførelse"
				component={Link}
				to={to}
				$isOverdue={isOverdue}
			>
				<CalendarMonthIcon />
			</TaskDeadlinesIconButton>
		</TaskDeadlinesCountBadge>
	);
}

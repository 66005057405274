import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import debounce from 'lodash/debounce';
import styled from 'styled-components';
import MUITextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import { useAppFilters } from 'web/contexts/AppFiltersContext';

import Icon from 'components/Icon';

const SearchBarWrapper = styled.div`
	margin: 0;
	flex: 1 1 0%;
`;

export default function SearchBar({ label }) {
	const { setSearch } = useAppFilters();
	const inputRef = useRef();

	const [params] = useSearchParams();
	const searchParam = params.get('search');

	// We can't use the value directly beacuse we want to debounce the search.
	const handleChange = debounce(({ target }) => setSearch(target.value), 750);

	useEffect(() => {
		setSearch(searchParam);
		inputRef.current.value = searchParam;
	}, [searchParam]);

	return (
		<SearchBarWrapper>
			<MUITextField
				label={label}
				inputRef={inputRef}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<Icon icon="search" />
						</InputAdornment>
					),
				}}
				variant={'outlined'}
				fullWidth={true}
				onChange={handleChange}
			/>
		</SearchBarWrapper>
	);
}

import { createContext, useState, useContext, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Dialog from '@mui/material/Dialog';

import { roles } from 'server/context/roles';

import {
	getVerificationOperation,
	webComponentsLookup,
	verificationKeys,
} from 'utils/userVerifications';

import { CURRENT_USER } from 'api/queries/userQueries';

const userRoleIds = roles.map(({ _id }) => _id);

export const UserContext = createContext({
	user: null,
});

export const useUser = () => useContext(UserContext);

const UserDialog = () => {
	const { user } = useUser();
	const [isOpen, setIsOpen] = useState(false);
	const [unverifiedVerifications, setUnverifiedVerifications] = useState([]);
	const [currentModal, setCurrentModal] = useState({ type: null, props: {} });

	const ActiveModal = webComponentsLookup[currentModal.type];

	useEffect(() => {
		if (!user) return;

		let unverified =
			verificationKeys?.reduce((prev, current) => {
				const verification = user?.userVerifications?.find(
					({ type }) => type === current
				);

				if (!verification || verification.length === 0) {
					return prev;
				}

				if (verification?.isVerified) {
					return prev;
				}

				const operation = getVerificationOperation(
					user,
					current,
					verification?.value
				);

				if (!operation) {
					return prev;
				}

				return [...prev, operation];
			}, []) || [];

		if (unverified?.length === 0) {
			//Get  session storage
			const sessionKey = 'hideUserLastVerification';
			const hideUserLastVerification = sessionStorage.getItem(sessionKey);

			// If user has chosen to hide the last verification modal, return
			if (hideUserLastVerification === 'true') return;

			//Date 6 months ago
			const today = new Date();
			const sixMonthsAgo = new Date(
				today.getFullYear(),
				today.getMonth() - 6,
				today.getDate()
			);

			// Check if date from last verification is more than 6 months ago
			if (
				!user.userLastVerification ||
				user.userLastVerification < sixMonthsAgo.toISOString()
			) {
				unverified = [
					{
						type: 'detailsVerify',
						props: {
							user,
							setIsOpen,
							sessionKey,
						},
					},
				];
			}
		}

		setUnverifiedVerifications(unverified);
	}, [user?.updatedAt?.toString()]);

	useEffect(() => {
		if (unverifiedVerifications.length === 0) {
			setIsOpen(false);
			return;
		}

		const modal = unverifiedVerifications[0] || {
			type: null,
			props: {},
		};

		setCurrentModal(modal);
		setIsOpen(true);

		return () => {};
	}, [unverifiedVerifications]);

	return (
		<Dialog
			open={isOpen}
			scroll="body"
			aria-labelledby="user-verification-dialog-title"
		>
			{currentModal.type && <ActiveModal {...currentModal.props} />}
		</Dialog>
	);
};

export default function UserProvider({ children }) {
	const { data: { currentUser } = { currentUser: null }, loading } = useQuery(
		CURRENT_USER,
		{
			fetchPolicy: 'network-only',
		}
	);

	const userHasOneOfRoles = roles => {
		if (!currentUser) return false;

		if (typeof roles === 'string') {
			return currentUser.role === roles;
		}

		if (Array.isArray(roles)) {
			return roles.includes(currentUser.role);
		}

		return false;
	};

	const userHasMinRole = role => {
		if (!currentUser) return false;

		if (typeof role !== 'string') {
			throw new Error('userHasMinRole: Role must be a string');
		}

		const minRoleIndex = userRoleIds.indexOf(role);
		const currentUserRoleIndex = userRoleIds.indexOf(currentUser.role);

		if (minRoleIndex === -1) {
			throw new Error(
				`userHasMinRole: Role not found. Check role name: ${role}`
			);
		}

		return currentUserRoleIndex >= minRoleIndex;
	};

	const userHasRole = role => {
		return currentUser?.role === role;
	};

	return (
		<UserContext.Provider
			value={{
				userLoading: loading,
				user: currentUser,
				userHasOneOfRoles,
				userHasMinRole,
				userHasRole,
			}}
		>
			{children}

			<UserDialog />
		</UserContext.Provider>
	);
}
